import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BadgesComponentCommon } from '@libs/components/badges/badges.component.common';
import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { IApplicationState } from '@libs/store/application-state';

import { BoostLastViewersService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';

@Component({
  selector: 'mp-badges',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './badges.html',
  standalone: false,
})
export class BadgesComponent extends BadgesComponentCommon {
  public getAttributes: IProfileAttributes[] = [
    {
      isVerified: (): boolean => this.isMelt(),
      className: 'changed-attribute',
      imagePath: '/assets/img/melt-badge-only.svg',
      imageAlt: 'common.list.melt',
    },
    {
      isVerified: (): boolean => this.isElite(),
      imagePath: '/assets/img/elite-badge-only-gold.svg',
      imageAlt: 'modules.main.pages.profile.member_elite',
    },
    {
      isVerified: (): boolean => this.isEligibleToPremiumBadge(),
      imagePath: '/assets/img/sbp-diamond-circle.svg',
      imageAlt: 'modules.main.pages.profile.member_sbp',
    },
    {
      isVerified: (): boolean => this.isVerified(),
      imagePath: '/assets/img/shield.svg',
      imageAlt: 'modules.main.pages.profile.member_verified',
    },
    {
      isVerified: (): boolean => this.isNew(),
      imagePath: '/assets/img/new.svg',
      imageAlt: 'modules.main.pages.profile.member_new',
      interpolateParams: { gender: this.getGender() },
    },
    {
      isVerified: (): boolean => this.sawMeInLastBoost(),
      imagePath: '/assets/img/viewed-me-boost-badge-only.svg',
      imageAlt: 'modules.main.pages.profile.saw_me_in_last_boost',
    },
  ];

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected store: Store<IApplicationState>,
    public translate: TranslateService,
    protected boostLastViewersService: BoostLastViewersService,
  ) {
    super(changeDetector, store, translate, boostLastViewersService);
  }
}
