import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionbarPageTitleComponentCommon } from '@libs/modules/shared/actionbar/actionbar-page-title/actionbar-page-title.component.common';

@Component({
  selector: 'mp-actionbar-page-title',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-page-title.html',
  standalone: false,
})
export class ActionbarPageTitleComponent extends ActionbarPageTitleComponentCommon {
  //
}
