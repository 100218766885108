import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Registration } from '@libs/interfaces/registration/registration';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

@Component({
  selector: 'mp-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TextFieldComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() label = '';
  @Input() controlName = '';
  @Input() placeholder = '';
  @Input() checkField = false;
  @Input() type = '';
  @Input() canShowFeedback = false;
  @Input() successMessage = '';
  @Input() requiredMessage = '';
  @Input() autoCapitalize: 'on' | 'off' = 'on';
  @Input() shouldShowSuccessMessage = true;

  protected subscriptions: Subscription[] = [];

  constructor(protected changeDetectorReference: ChangeDetectorRef) {
    //
  }

  ngOnInit(): void {
    this.handleValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  protected handleValueChanges(): void {
    this.subscriptions.push(
      FormHelpers.getControlStatusChanges$(this.form, this.controlName).subscribe({
        next: (): void => {
          this.changeDetectorReference.markForCheck();
        },
      }),
    );
  }

  public canShowValidationError(): boolean {
    return FormHelpers.canShowValidationErrorOnRealTime(this.form, this.controlName);
  }

  public canShowSuccessMessage(): boolean {
    return FormHelpers.canShowSuccessMessage(this.form, this.controlName);
  }

  public getFeedbackModifierClass(): string {
    if (!this.canShowFeedback) {
      return '';
    }

    return Registration.VALIDATION_FEEDBACK_MODIFIER_CLASS;
  }
}
