import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';

import { BoostService } from '@meupatrocinio/modules/shared/boost-shared/services/boost/boost.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mp-boost-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './boost-nagbar.html',
  standalone: false,
})
export class BoostNagbarComponent implements OnInit {
  private boostService = inject(BoostService);
  private auth = inject(AuthenticationService);
  private router = inject(Router);
  private store = inject(Store<IApplicationState>);

  public profiles$: Observable<IProfile[]> = of([]);
  protected boostRoute: string[] = ['main', 'boost'];
  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  ngOnInit(): void {
    this.profiles$ = this.boostService.getBoostProfiles$();
  }

  protected get user(): UserCommon {
    return this.auth.get();
  }

  public getProfilePicture(profile: IProfile): string {
    return ImageHelper.getProfilePicture(profile, this.auth.get(), UserCommon.PICTURE_SMALL);
  }

  public navigateToBoostRoute(): void {
    this.router.navigate(this.boostRoute);
  }

  public getNagbarSubtitle(): string {
    if (!UserCommon.isBaby(this.user)) {
      return 'modules.main.shared.boost_nagbar.subtitle.sd';
    }

    return 'modules.main.shared.boost_nagbar.subtitle.sb';
  }
}
