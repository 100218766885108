import { createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as BoostToastActions from '@libs/store/boost-toast/actions';
import { IBoostToastState, boostToastAdapter, boostToastInitialState } from '@libs/store/boost-toast/state';

export const boostToastReducer = createReducer(
  boostToastInitialState,
  on(
    BoostToastActions.upsertProfile,
    (state: IBoostToastState, { profile }: { profile: IProfile }): IBoostToastState => {
      return boostToastAdapter.upsertOne(profile, state);
    },
  ),
  on(BoostToastActions.removeAllProfiles, (state: IBoostToastState): IBoostToastState => {
    return boostToastAdapter.removeAll(state);
  }),
);
