import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { StepConfirmationComponentCommon } from '@libs/modules/frictionless-registration/step-confirmation/step-confirmation.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Component({
  selector: 'mp-step-confirmation',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step-confirmation.html',
  standalone: false,
})
export class StepConfirmationComponent extends StepConfirmationComponentCommon {
  constructor(
    protected auth: AuthenticationService,
    protected store: Store<IApplicationState>,
    protected userService: UserService,
    protected modalService: ModalService,
    protected router: Router,
  ) {
    super(userService, store, modalService);
  }

  public logout(): void {
    this.auth.logout();
    this.router.navigate(['initial', 'login'], { replaceUrl: true });
  }

  public isEmailEmpty(): boolean {
    return this.email && this.email.length === 0;
  }
}
