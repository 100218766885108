import { createReducer, on } from '@ngrx/store';

import * as ReferralStatusActions from '@libs/store/referral-status/actions';
import {
  IReferralStatusState,
  referralStatusAdapter,
  referralStatusInitialState,
} from '@libs/store/referral-status/state';

export const referralStatusReducer = createReducer(
  referralStatusInitialState,
  on(ReferralStatusActions.upsertOneReferralStatus, (state, { referralStatus }): IReferralStatusState => {
    return referralStatusAdapter.upsertOne(referralStatus, state);
  }),
);
