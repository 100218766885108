import { Component, inject } from '@angular/core';
import { IBoostTimeRemaining } from '@libs/modules/boost/interface/boost-time-remaining';
import { BoostProgressService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mp-boost-progress',
  templateUrl: './boost-progress.html',
  standalone: false,
})
export class BoostProgressComponent {
  private boostProgressService = inject(BoostProgressService);

  protected canUnfillProgress = false;
  public boostTimeRemaining$: Observable<IBoostTimeRemaining> = of(
    this.boostProgressService.getDefaultTimeRemainingValues(),
  );
  public progressInPercentage$: Observable<string> = of(this.boostProgressService.getDefaultProgress());

  ngOnInit(): void {
    this.boostProgressService.initilizeCountdownSubjects();
    this.boostProgressService.startCountdownSequence();
    this.boostTimeRemaining$ = this.boostProgressService.getCountdown$();
    this.progressInPercentage$ = this.boostProgressService.getProgressInPercentage$();
  }

  ngOnDestroy(): void {
    this.boostProgressService.destroyCountdownSubjects();
  }

  getFormattedTimeRemaining({ hours, minutes, seconds }: IBoostTimeRemaining): string {
    return `${hours}:${minutes}:${seconds}`;
  }
}
