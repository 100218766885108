import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { PaymentInfoServiceCommon } from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions } from '@libs/store/payment-info';

import { Config } from '@meupatrocinio/config';
import { IPixPaymentNotification } from '@meupatrocinio/modules/main/services/payment/interfaces/pix-payment-notification.interface';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { SocketService } from '@meupatrocinio/services/socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfoService extends PaymentInfoServiceCommon implements OnDestroy {
  private readonly subscription: Subscription;
  public terminateCheckSubject = new Subject<never>();

  constructor(
    protected authHttp: AuthHttpService,
    protected store: Store<IApplicationState>,
    private readonly socketService: SocketService,
  ) {
    super(store);
    this.subscription = new Subscription();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getPaymentHistory() {
    const endpoint: string = `${Config.serverIp}v2/payment/history`;

    return this.authHttp.get(endpoint);
  }

  public loadPaymentProvider() {
    const endpoint: string = `${Config.serverIp}v2/payment/provider`;

    return this.authHttp.get(endpoint);
  }

  public terminateCheck() {
    this.terminateCheckSubject.next();
  }

  public listenToPixPayment(productUuid: string) {
    this.subscription.add(
      this.socketService
        .listenUserEvent('.paymentStatus')
        .pipe(
          take(1),
          tap({
            next: (paymentData) => {
              const { status } = JSON.parse(paymentData) as IPixPaymentNotification;

              if (status === 'approval') {
                this.onPaymentSuccess(productUuid);

                return;
              }

              this.onPaymentError();
            },
          }),
          takeUntil(this.terminateCheckSubject),
        )
        .subscribe(),
    );
  }

  public onPaymentError() {
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_ERROR,
      }),
    );
  }

  public onPaymentSuccess(productUuid: string) {
    this.store.dispatch(
      PaymentInfoActions.handlePixSuccessPayment({
        productUuid,
      }),
    );
  }

  public getPaymentStatus(idempotencyKey: string) {
    const endpoint = `${Config.serverIp}v2/payment/check-payment-status/${idempotencyKey}`;

    return this.authHttp.get(endpoint);
  }
}
