import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PaymentModalPixFormService } from '@meupatrocinio/services/payment-modal-pix-form/payment-modal-pix-form.service';
@Component({
  selector: 'mp-payment-modal-pix-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payment-modal-pix-form.html',
  standalone: false,
})
export class PaymentModalPixFormComponent implements OnInit, OnDestroy {
  private paymentModalPixFormService = inject(PaymentModalPixFormService);

  @Output() public readonly cpfData: EventEmitter<string> = new EventEmitter<string>();
  @Input() public submitButtonTranslation = 'modules.main.pages.payment.pay';
  @Input() public showLoading = false;

  public isValidCPF = false;
  public paymentModalPixForm: FormGroup = this.paymentModalPixFormService.getFormGroup();

  public ngOnInit() {
    this.initializeForm();
  }

  public ngOnDestroy() {
    this.resetForm();
    this.paymentModalPixFormService.clearAllData();
  }

  public initializeForm() {
    this.paymentModalPixForm = this.paymentModalPixFormService.getFormGroup();
  }

  public makePayment() {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.handlePaymentData();
  }

  public getErrorMessage(controlName: string) {
    return this.paymentModalPixFormService.getFormValidationErrosMessage(controlName);
  }

  public isFormGroupValid() {
    return this.paymentModalPixFormService.isFormValid();
  }

  public resetForm() {
    this.paymentModalPixForm.reset();
  }

  public handlePaymentData() {
    this.cpfData.emit(this.paymentModalPixForm.get('cpf')?.value);
  }
}
