import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EmailSuggestionService } from '@libs/services/email-suggestion/email-suggestion.service';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

@Component({
  selector: 'mp-email-field',
  templateUrl: './email-field.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmailFieldComponent implements OnInit, OnDestroy {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public controlName = '';
  @Input() public inputId = 'email';
  @Input() public canShowLoadingIndicator = false;
  @Input() public canShowValidationError = false;

  @ViewChild('emailInputElement', { static: false })
  emailInputElement: ElementRef<HTMLInputElement>;

  protected email = '';
  protected suggestedEmail = '';
  protected emailSuggestionText = '';
  protected subscriptions: Subscription[] = [];

  protected readonly DELAY_BEFORE_EMAIL_SUGGESTION = 800;

  constructor(
    protected emailSuggestionService: EmailSuggestionService,
    protected changeDetectorReference: ChangeDetectorRef,
  ) {
    //
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form
        .get(this.controlName)
        .valueChanges.pipe(debounceTime(this.DELAY_BEFORE_EMAIL_SUGGESTION))
        .subscribe((value: string) => {
          this.email = value;
          this.setEmailSuggestion();
          this.changeDetectorReference.markForCheck();
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  protected setEmailSuggestion() {
    const emailSuggestionObject = this.emailSuggestionService.getEmailSuggestion(this.email);

    this.suggestedEmail = emailSuggestionObject.suggestedEmail;
    this.emailSuggestionText = emailSuggestionObject.suggestionString;
  }

  public isEmailSuggestionEmpty() {
    return this.emailSuggestionText.length === 0;
  }

  public replaceEmailToSuggestion() {
    FormHelpers.setFormControlValue(this.form, this.controlName, this.suggestedEmail);
    this.resetEmailSuggestion();
  }

  protected resetEmailSuggestion() {
    this.emailSuggestionText = '';
    this.suggestedEmail = '';
    this.emailInputElement.nativeElement.focus();
  }

  public getEmailSuggestionText() {
    return this.emailSuggestionText;
  }
}
