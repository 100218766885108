import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesSearchPayload {
  profiles: IProfile[];
}

export const profilesSearchReceived = createAction('[Profiles Search] Received', props<IProfilesSearchPayload>());

export const profilesSearchRemoved = createAction('[Profiles Search] Removed', props<IProfilesSearchPayload>());

export const profilesSearchRefreshed = createAction('[Profiles Search] Refreshed');
