import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FrictionlessStep2ComponentCommon } from '@libs/modules/frictionless-registration/step2/step2.component.common';
import { IDataPicture } from '@libs/shared/interfaces/data-photo.interface';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { IApplicationState } from '@libs/store/application-state';
import { ImageReceivedAction } from '@libs/store/registration/actions/frictionless-image.action';

import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-frictionless-step2',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step2.html',
  standalone: false,
})
export class FrictionlessStep2Component extends FrictionlessStep2ComponentCommon {
  public pictureData: string[] = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected modalService: ModalService,
  ) {
    super(store);
  }

  public saveUser(): void {
    const pictures = this.pictureData.filter((picture): boolean => !!picture);

    this.store.dispatch(
      new ImageReceivedAction({
        data: {
          profile_image: pictures[0],
          image: pictures.slice(1),
        },
      }),
    );
  }

  public restoreUserData(value: IRegistrationPayload): void {
    this.pictureData = [value.profile_image, ...(value.image || [])];
    this.hasPicture = !!this.pictureData.filter((picture): boolean => !!picture).length;
  }

  public setUserHasPictures(boxData: IDataPicture): void {
    this.hasPicture = boxData.hasPicture;
    this.pictureData[boxData.index] = boxData.pictureName;
  }
}
