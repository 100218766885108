import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum LoadingThemes {
  mpDefault = '#E00C00',
  mpBoost = '#4400BB',
}

export enum LoadingThemesNames {
  mpDefault = 'mpDefault',
  mpBoost = 'mpBoost',
}

@Component({
  selector: 'mp-loading',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './loading.html',
  standalone: false,
})
export class LoadingComponent {
  @Input() theme: string = LoadingThemes.mpDefault;

  getBorderColor(): string {
    if (this.theme === LoadingThemesNames.mpBoost) {
      return LoadingThemes.mpBoost;
    }

    return LoadingThemes.mpDefault;
  }
}
