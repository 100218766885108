<div *ngIf="!navHidden">
    <header 
        class="visible-md visible-lg !tw-bg-nav-base"
    >
        <div class="container">
            <mp-user-menu></mp-user-menu>
        </div>
    </header>
</div>
<main
    class="error-404 !tw-bg-404"
    [ngClass]="{full: navHidden}"
>
    <div class="error-404-inner">
        <p
            class="logo tw-flex tw-items-center tw-justify-center"                 
        >
            <mp-brand-image
                [src]="'404-logo.svg'"
                [description]="'logo 404'"
                [class]="{'!tw-h-24': brand === 'meuprincipe'}"
            />
        </p>
        <p class="title">
            {{ 'modules.page_not_found.title' | translate }}
        </p>
        <p class="blurb">
            {{ 'modules.page_not_found.blurb' | translate }}
        </p>
        <p>
            <a
                class="back-to-start !tw-bg-primary-dark"
                href="/"
            >
                {{ 'modules.page_not_found.back_to_start' | translate }}
            </a>
        </p>
    </div>
</main>
<mp-responsive-menu *ngIf="!navHidden"></mp-responsive-menu>
