import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { UploadImageBoxComponentCommon } from '@libs/components/upload-image-box/upload-image-box.component.common';
import { ICallbackData } from '@libs/shared/interfaces/callback-data.interface';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { ImageService } from '@meupatrocinio/services/image.service';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-upload-image-box',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './upload-image-box.html',
  styleUrls: ['./upload-image-box.component.scss'],
  standalone: false,
})
export class UploadImageBoxComponent extends UploadImageBoxComponentCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected file: Blob;
  public isCropping$ = new BehaviorSubject<{ isCropping: boolean; index: number }>({ isCropping: true, index: null });

  @ViewChildren('fileInput') filesForm: QueryList<ElementRef>;

  constructor(
    protected modalService: ModalService,
    protected advancedModalService: AdvancedModalService,
    protected imageService: ImageService,
    protected store: Store<IApplicationState>,
  ) {
    super(store);

    this.subscriptions.push(
      this.store
        .select('storeImageOnRegistration')
        .pipe(takeWhile((): boolean => this.isRegistration))
        .subscribe((imageData: IRegistrationPayload): void => {
          this.pictureData = [imageData.profile_image, ...imageData.image];
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public isCropping(state: { index: number }, currentIndex: number) {
    return currentIndex === state.index;
  }

  public loadPicture = (picture: Blob, index: number): void => {
    const reader = new FileReader();
    reader.onload = (): void => {
      this.pictureData[index] = <string>reader.result;
      if (this.isRegistration) {
        this.pictureBoxData.emit({
          hasPicture: true,
          pictureName: <string>reader.result,
          index,
        });
      }
    };
    reader.readAsDataURL(picture);
  };

  getImageUploadedCallback = (event: ICallbackData, callbackError?: (error: HttpErrorResponse) => void): void => {
    this.isUploading = true;
    this.file = event.image;
    if (!this.isRegistration) {
      event.request.subscribe(
        (requestEvent: HttpEvent<Event>): void => {
          if (requestEvent.type === HttpEventType.Response) {
            this.isUploading = false;
            this.loadPicture(this.file, this.imageIndex);
          }
        },
        (error: HttpErrorResponse): void => {
          this.isUploading = false;
          if (callbackError !== null) {
            callbackError(error);
          }
        },
      );
      return;
    }
    this.loadPicture(this.file, this.imageIndex);
    this.isCropping$.next({ isCropping: false, index: null });
    this.isUploading = false;
  };

  public getCameraImg(): string {
    return UserCommon.resolveImageName('camera', '.svg');
  }

  getAcceptedImageTypes(): string {
    return PhotoCommon.TYPE_PHOTOS_ACCEPTED_IN_UPLOAD;
  }

  private cancelCallback = () => {
    this.isCropping$.next({ isCropping: false, index: null });
  };

  public processPicture(event: Event, index: number): void {
    const input: HTMLInputElement = <HTMLInputElement>event.target;

    if (input.files === undefined || input.files.length === 0) {
      return;
    }

    this.file = input.files[0];
    this.imageIndex = index;

    this.imageService.cropPhoto({
      file: this.file,
      type: this.photoType,
      callback: this.getImageUploadedCallback,
      uploadCancelledCallback: this.cancelCallback,
      isRegistration: this.isRegistration,
    });
    this.isCropping$.next({ isCropping: true, index });
    this.filesForm.forEach((inputValue): void => {
      inputValue.nativeElement.value = '';
    });
  }
}
