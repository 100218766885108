<div *ngIf="referrals$ | async as referral">
    <div class="referral-friend-counter">
        <div class="referral-container">
            <div
                class="referral-badge-list"
                [ngClass]="{ 'referral-badge-list--centered': shouldBadgesBeCentered(referral) }"
            >
                <ng-container *ngFor="let badge of badgePremiumCounter(referral)">
                    <div class="referral-friend-counter__progress-bar">
                        <img
                            [src]="getBadgePremiumState(referral, badge)"
                            alt="premium badge"
                        >
                        <div class="referral-friend-counter__progress-bar__progress">
                            <svg>
                            <circle
                                [ngClass]="getProgressBarModifierClass(referral, badge)"
                                cx="32" cy="32" r="32"
                                class="referral-friend-counter__progress-bar__progress__circle !tw-stroke-primary-base"
                            ></circle>
                            </svg>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <p
            [innerHTML]="getScoreSubscribedText(referral)"
            class="referral-friend-counter__counter"
        ></p>
        <p
            [innerHTML]="getAwardMessage(referral)"
            *ngIf="canShowAwardMessage(referral)"
            class="referral-friend-counter__premium-description [&>strong]:!tw-text-primary-base"
        ></p>
    </div>
</div>
