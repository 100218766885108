import { createReducer, on } from '@ngrx/store';

import * as ReferralActions from '@libs/store/referral/actions';
import { IReferralState, referralAdapter, referralInitialState } from '@libs/store/referral/state';

export const referralReducer = createReducer(
  referralInitialState,
  on(ReferralActions.upsertManyReferrals, (state, { referrals }): IReferralState => {
    return referralAdapter.upsertMany(referrals, state);
  }),
);
