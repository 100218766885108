import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { NagbarCommon } from '@meupatrocinio/modules/nagbar/nagbar.common';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { UserService } from '@meupatrocinio/services/user.service';

import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'mp-mobile-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './mobile-nagbar.html',
  standalone: false,
})
export class MobileNagbarComponent implements OnInit, OnDestroy {
  static componentName: string = NagbarCommon.MOBILE_NAGBAR_COMPONENT_NAME;
  public emailObfuscated: string;
  public isClicked = false;
  public loading = false;
  public countDown = 30;

  protected user: UserCommon;
  protected numEmptyFields: number;
  protected interval: any;
  protected diffSeconds: number;
  protected clickSavedTime: number;
  protected remainingTime: number;
  protected checkTime: boolean;

  protected subscriptions: Subscription[] = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected cookies: CookieService,
    protected userService: UserService,
    protected modalService: ModalService,
  ) {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        this.emailObfuscated = user.email_obfuscated;

        if (!user || !user.profile_extended) {
          this.numEmptyFields = FrictionlessCommon.checkFields.length + FrictionlessCommon.checkExtendedFields.length;

          return;
        }

        this.user = user;
        this.numEmptyFields = FrictionlessCommon.getNumberOfFilledFields(this.user);
      }),
    );
  }

  ngOnInit(): void {
    this.checkTime = this.cookies.check('clickSave');
    this.clickSavedTime = +this.cookies.get('clickSave');

    if (this.countDown <= 0) {
      this.clearAll();

      return;
    }

    if (this.checkTime) {
      this.keepCountDown();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public canShowFrictionlessNagbar(): boolean {
    if (!this.user) {
      return false;
    }

    return !FrictionlessCommon.isComplete(this.user);
  }

  hasFilledAllFields(): boolean {
    return this.getCompletenessFormattedPercentage() === 100;
  }

  public getCompletenessSVGPercentage(): string {
    return this.getCompletenessFormattedPercentage() + ', 100';
  }

  public getCompletenessFormattedPercentage(): number {
    return Math.floor(FrictionlessCommon.getCompletenessPercentage(this.numEmptyFields) * 100);
  }

  public isVerified(): boolean {
    return this.user !== undefined && !UserCommon.isBaby(this.user) && !UserCommon.hasEmailVerified(this.user);
  }

  public isDaddyMommyUser(): boolean {
    return UserCommon.isDaddyMommy(this.user);
  }

  public confirmationEmail(): Subscription {
    this.loading = true;

    return this.userService.confirmationEmail().subscribe(
      (): void => {
        this.intervalToSendEmail();
      },
      (): void => {
        this.modalService.open('modules.main.shared.mobile_nagbar.error');
      },
    );
  }

  public intervalToSendEmail(): void {
    this.loading = false;
    this.isClicked = true;

    if (!this.checkTime) {
      this.saveCookie('clickSave', Date.now());
    }
    this.interval = setInterval((): void => {
      if (this.countDown <= 0) {
        this.clearAll();
        return;
      }

      this.countDown -= 1;
      this.saveCookie('countDown', this.countDown);
    }, 1000);
  }

  public clearAll(): void {
    clearTimeout(this.interval);
    this.isClicked = false;
    this.countDown = 30;
    this.remainingTime = 0;
    this.diffSeconds = 0;
    this.checkTime = false;
    this.deleteCookies();
  }

  public deleteCookies(): void {
    this.cookies.deleteAll();
  }

  public keepCountDown(): void {
    this.clickSavedTime = parseInt(this.cookies.get('clickSave'), 10);
    this.diffSeconds = Math.floor((Date.now() - this.clickSavedTime) / 1000);
    this.remainingTime = this.countDown - this.diffSeconds;
    this.countDown = this.remainingTime;

    if (!this.remainingTime || this.remainingTime > 30 || this.countDown <= 0) {
      this.clearAll();
      return;
    }
    this.intervalToSendEmail();
  }

  public saveCookie(name: string, value: number): void {
    this.cookies.set(name, value.toString());
  }

  public hasNoMembershipExpiration(): boolean {
    return !UserCommon.hasMembershipExpiration(this.user);
  }

  public navigateToUpgradeAccount() {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }
}
