import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { LocationServiceCommon } from '@libs/services/location/location.service.common';
import { IRegion } from '@libs/services/location/region/region';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { citiesReceived } from '@libs/store/location/actions/cities.action';
import { countriesReceived } from '@libs/store/location/actions/countries.action';
import { regionsReceived } from '@libs/store/location/actions/regions.action';
import { arrayFind } from '@libs/utils/array-functions';

import { Config } from '@meupatrocinio/config';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends LocationServiceCommon implements OnDestroy {
  public readonly DEFAULT_COUNTRY_VALUE: string = 'Brazil';

  protected user: UserCommon | undefined;
  protected subscriptions: Subscription[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<IApplicationState>,
  ) {
    super();

    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        if (!user) {
          return;
        }

        this.user = user;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public loadCountries(): void {
    const endpoint: string = Config.serverIp + 'location/countries';

    this.http.get(endpoint, { observe: 'response' }).subscribe((data: any): void => {
      const countries: ICountry[] = data.body;

      this.store.dispatch(countriesReceived({ countries }));
    });
  }

  public loadRegions(country: string): void {
    if (!country) {
      return;
    }

    const endpoint: string = Config.serverIp + 'location/' + country + '/states';
    this.http.get(endpoint, { observe: 'response' }).subscribe((data: any): void => {
      const regions: IRegion[] = data.body;

      this.store.dispatch(regionsReceived({ regions }));
    });
  }

  public loadCities(region: string): void {
    if (!region) {
      return;
    }

    const endpoint: string = Config.serverIp + 'location/' + region + '/cities';
    this.http.get(endpoint, { observe: 'response' }).subscribe((data: any): void => {
      const cities: ICity[] = this.formatCitiesWithCapital(data.body);

      this.store.dispatch(citiesReceived({ cities }));
    });
  }

  public getCountryCode(countries: ICountry[], countryName: string): string {
    if (countries === undefined) {
      return '';
    }

    const country = arrayFind(countries, (value: ICountry): boolean => value.Country_str_name === countryName);

    if (!country) {
      return '';
    }

    return country.Country_str_code;
  }

  public getRegionCode(regions: IRegion[], regionName: string): string {
    if (regions === undefined) {
      return '';
    }

    const region = arrayFind(regions, (value: IRegion): boolean => value.Admin1_str_name === regionName);

    if (!region) {
      return '';
    }

    return region.Admin1_str_code;
  }

  public getCityCode(cities: ICity[], cityName: string): number {
    if (cities === undefined) {
      return 0;
    }

    const city = arrayFind(cities, (value: ICity): boolean => value.Feature_str_name === cityName);

    if (!city) {
      return 0;
    }

    return city.Feature_int_id;
  }

  public getCountryName(countries: ICountry[], countryCode: string): string {
    if (countries === undefined) {
      return '';
    }

    const country = arrayFind(countries, (value: ICountry): boolean => value.Country_str_code === countryCode);

    if (!country) {
      return '';
    }

    return country.Country_str_name;
  }

  public getRegionName(regions: IRegion[], regionCode: string): string {
    if (regions === undefined) {
      return '';
    }

    const region = arrayFind(regions, (value: IRegion): boolean => value.Admin1_str_code === regionCode);

    if (!region) {
      return '';
    }

    return region.Admin1_str_name;
  }

  public getCityName(cities: ICity[], cityCode: number): string {
    if (cities === undefined) {
      return '';
    }

    const city = arrayFind(cities, (value: ICity): boolean => value.Feature_int_id === cityCode);

    if (!city) {
      return '';
    }

    return city.Feature_str_name;
  }

  public getCountriesNames(countries: ICountry[]): string[] {
    if (countries === undefined) {
      return [];
    }
    return countries.map((value: ICountry): string => value.Country_str_name);
  }

  public getRegionsNames(regions: IRegion[]): string[] {
    if (regions === undefined) {
      return [];
    }
    return regions.map((value: IRegion): string => value.Admin1_str_name);
  }

  public getCitiesNames(cities: ICity[]): string[] {
    if (cities === undefined) {
      return [];
    }
    return cities.map((value: ICity): string => value.Feature_str_name);
  }

  public filterCountries = (country: ICountry): boolean => {
    if (this.user === undefined || country === undefined) {
      return false;
    }

    return country.Country_str_name === this.user.countryName || !country.Country_optional;
  };

  public formatCitiesWithCapital(cities) {
    if (cities.lenght === 0) {
      return [];
    }
    const capital = cities[0];
    const otherCities = cities.slice(1);

    const citiesFormatted = [...otherCities, capital].sort((prev, before) => {
      return prev.Feature_str_name.localeCompare(before.Feature_str_name);
    });

    return [capital, ...citiesFormatted];
  }
}
