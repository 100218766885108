import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { RulesModalComponentCommon } from '@libs/modules/main/shared/modals/rules-modal.component.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

interface IModalData {
  agreedCallback?(): void;
}

@Component({
  selector: 'mp-rules-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './rules-modal.html',
  standalone: false,
})
export class RulesModalComponent extends RulesModalComponentCommon implements IModalComponent<IModalData> {
  constructor(
    protected auth: AuthenticationService,
    protected modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: IModalData,
  ) {
    super(auth);
  }

  public agree(): void {
    if (this.data && this.data.agreedCallback) {
      this.data.agreedCallback();
    }

    this.modalRef.close();
  }

  public decline(): void {
    this.modalRef.close();
  }
}
