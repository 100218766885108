import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, Type, inject } from '@angular/core';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { ModalViewServiceCommon } from '@libs/components/modal-view/modal-view.service.common';
import { MODAL_VIEW_OPTIONS } from '@libs/components/modal-view/modal-view.tokens';

import { ModalViewReference } from '@meupatrocinio/modules/main/shared/modal-view/modal-view-reference';
import { ModalViewComponent } from '@meupatrocinio/modules/main/shared/modal-view/modal-view.component';
import { InjectComponentService } from '@meupatrocinio/services/inject-component.service';
import { ModalViewOverlayConfig } from '@meupatrocinio/services/modal-view/modal-view-overlay-config';

@Injectable({
  providedIn: 'root',
})
export class ModalViewService extends ModalViewServiceCommon {
  private overlay = inject(Overlay);
  private injector = inject(Injector);
  private injectComponentService = inject(InjectComponentService);

  private overlayRef?: OverlayRef;
  private modalRef?: ModalViewReference;

  public open(component: Type<unknown>, options: IModalViewOptions): void {
    this.closeExisting();
    this.createOverlay(options);
    this.attachModalView(ModalViewComponent, component, options);
  }

  public close(): void {
    this.modalRef?.close();
  }

  private closeExisting(): void {
    this.modalRef?.diposeModalView();
  }

  private createOverlay(options: IModalViewOptions): void {
    const config = this.createOverlayConfig(options);
    this.overlayRef = this.overlay.create(config);
    this.modalRef = new ModalViewReference(this.overlayRef);
  }

  private createOverlayConfig(options: IModalViewOptions): OverlayConfig {
    return new ModalViewOverlayConfig(this.overlay).getOverlayConfig(options);
  }

  private attachModalView(
    modalViewComponent: ComponentType<ModalViewComponent>,
    component: Type<unknown>,
    options: IModalViewOptions,
  ): void {
    if (!this.overlayRef || !this.modalRef) {
      return;
    }

    const injector = this.createPortalInjector(options);
    const portal = new ComponentPortal(modalViewComponent, null, injector);

    this.modalRef.componentInstance = this.overlayRef.attach(portal).instance;

    const { modalViewContent } = this.modalRef.componentInstance;
    this.injectComponentService.inject(component, modalViewContent, injector);
  }

  private createPortalInjector(options: IModalViewOptions): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_VIEW_OPTIONS,
          useValue: options,
        },
      ],
    });
  }
}
