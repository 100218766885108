import { Component, OnInit, inject } from '@angular/core';
import { Event, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { SubmenuV2ComponentCommon } from '@libs/components/submenu-v2/submenu-v2.component.common';
import { ILists, INITIAL_DM_STATE } from '@libs/modules/main/services/download-manager.service.common';
import { ISubmenuItems } from '@libs/shared/interfaces/submenu-items.interface';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { Config } from '@meupatrocinio/config';

import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'mp-submenu-v2',
  templateUrl: './submenu-v2.html',
  standalone: false,
})
export class SubmenuV2Component extends SubmenuV2ComponentCommon implements OnInit {
  private store = inject(Store);
  private router = inject(Router);
  private downloadManagerService = inject(DownloadManagerService);

  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  ngOnInit() {
    this.updateSubmenuItems();
    this.updateOnNavigation();
  }

  public navigateTo(route: any[], extras?: NavigationExtras) {
    this.router.navigate(route, extras);
  }

  public updateOnNavigation() {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event): void => {
        if (event instanceof NavigationEnd) {
          this.updateSubmenuItems();
        }
      }),
    );
  }

  public isLoading(routeName: string, itemIndex: number) {
    const kebabToCamel = (txt: string) => {
      return txt.replace(/-([a-z])/g, (_: string, letter: string) => {
        return letter.toUpperCase();
      });
    };

    const key = kebabToCamel(routeName);

    if (!(key in INITIAL_DM_STATE)) {
      return false;
    }

    return routeName && itemIndex === this.index
      ? this.downloadManagerService.isDownloading(key as keyof ILists)
      : false;
  }

  public selectItem(listItemIndex: number) {
    if (listItemIndex === this.index) {
      this.refreshList(listItemIndex);
      return;
    }

    this.navigateToIndex(listItemIndex);
  }

  public trackByRoute(_: number, menuItem: ISubmenuItems) {
    return menuItem.routeName;
  }

  public get brand() {
    return Config.brand;
  }
}
