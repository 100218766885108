import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mp-date-input]',
  standalone: false,
})
export class DateInputDirective {
  @Output() readonly formattedEmmiter: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.invalid') invalid = false;

  constructor(private readonly element: ElementRef) {
    //
  }

  static isValidDate(s: any): any {
    const bits: number = s.split('/').map((bit: string): number => parseInt(bit, 10));
    const d: Date = new Date(bits[2], bits[1] - 1, bits[0]);

    return d.getMonth() + 1 === bits[1];
  }

  @HostListener('focus', ['$event']) onFocus(): void {
    this.invalid = false;
  }

  @HostListener('blur', ['$event']) onBlur(): void {
    this.formatBirthdate();
  }

  @HostListener('keypress', ['$event']) onKeypress(event: any): any {
    return /[0-9]|\./.test(String.fromCharCode(event.keyCode)) && !event.shiftKey;
  }

  formatBirthdate(): void {
    const inputValue = this.element.nativeElement.value.replace(/\D/g, '');

    const d = inputValue.substring(0, 2);
    const m = inputValue.substring(2, 4);
    const y = inputValue.substring(4);

    this.element.nativeElement.value = d + ((m && '/' + m) || '') + ((y && '/' + y) || '');

    this.invalid = !DateInputDirective.isValidDate(this.element.nativeElement.value);

    if (!this.invalid) {
      this.formattedEmmiter.emit(y + '-' + m + '-' + d);
    }
  }

  public getValue(): any {
    return this.element.nativeElement.value;
  }
}
