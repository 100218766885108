import { createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as FavoriteToastActions from '@libs/store/favorite-toast/actions';
import { IFavoriteToastState, favoriteToastAdapter, favoriteToastInitialState } from '@libs/store/favorite-toast/state';

export const favoriteToastReducer = createReducer(
  favoriteToastInitialState,
  on(
    FavoriteToastActions.upsertProfile,
    (state: IFavoriteToastState, { profile }: { profile: IProfile }): IFavoriteToastState => {
      return favoriteToastAdapter.upsertOne(profile, state);
    },
  ),
  on(FavoriteToastActions.removeAllProfiles, (state: IFavoriteToastState): IFavoriteToastState => {
    return favoriteToastAdapter.removeAll(state);
  }),
);
