import { IProfile } from '@libs/shared/profile/profile';
import {
  profilesFavMyReceived,
  profilesFavMyRefreshed,
  profilesFavMyRemoved,
} from '@libs/store/profiles/actions/fav-my.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesFavMyReceived(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (currentState.includes(profile.profile_id)) {
      return currentState;
    }
    return [...currentState, profile.profile_id];
  }, state);
}

function onProfilesFavMyRemoved(state: number[], profiles: IProfile[]): number[] {
  const idsToRemove = profiles.map((profile) => profile.profile_id);

  return state.filter((id) => !idsToRemove.includes(id));
}

export const storeMyFavProfiles = createReducer(
  [] as number[],
  on(profilesFavMyReceived, (state, { profiles }) => onProfilesFavMyReceived(state, profiles)),
  on(profilesFavMyRemoved, (state, { profiles }) => onProfilesFavMyRemoved(state, profiles)),
  on(profilesFavMyRefreshed, () => []),
);
