import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';

import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { NagbarCommon } from '@meupatrocinio/modules/nagbar/nagbar.common';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { MembershipService } from '@meupatrocinio/services/membership.service';

@Component({
  selector: 'mp-payment-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './payment-nagbar.html',
  standalone: false,
})
export class PaymentNagbarComponent implements OnDestroy {
  static componentName: string = NagbarCommon.PAYMENT_NAGBAR_COMPONENT_NAME;
  public paymentStatus$: Observable<PaymentStatus>;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected auth: AuthenticationService,
    protected store: Store<IApplicationState>,
    protected membershipService: MembershipService,
    protected paymentInfoService: PaymentInfoService,
  ) {
    this.paymentStatus$ = this.store.pipe(select(PaymentInfoSelectors.selectPaymentStatus));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  closeBox(): void {
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_NONE,
      }),
    );
  }

  isPendingElite$(): Observable<boolean> {
    return this.membershipService.userHasPaidEliteAndIsPending$(this.auth.get());
  }

  isPendingElite(pendingElite: boolean): boolean {
    return pendingElite;
  }

  isBabyPremium(): boolean {
    return UserCommon.isBabyPremium(this.auth.get());
  }

  isNonElitePaymentOk(paymentStatus: PaymentStatus): boolean {
    return this.paymentInfoService.isNonElitePaymentOk(paymentStatus, this.auth.get());
  }

  isPaymentAwaiting(paymentStatus: PaymentStatus): boolean {
    return this.paymentInfoService.isPaymentAwaiting(paymentStatus);
  }

  isPaymentError(paymentStatus: PaymentStatus): boolean {
    return this.paymentInfoService.isPaymentError(paymentStatus);
  }

  isPaymentDone(paymentStatus: PaymentStatus): boolean {
    return this.paymentInfoService.isPaymentDone(paymentStatus);
  }
}
