import { IProfile } from '@libs/shared/profile/profile';
import {
  prependFavMeProfile,
  profilesFavMeReceived,
  profilesFavMeRefreshed,
  profilesFavMeRemoved,
} from '@libs/store/profiles/actions/fav-me.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesFavMeReceived(state: number[], profiles: IProfile[], reverse?: boolean): number[] {
  const profileIds = profiles.map((profile: IProfile): number => profile.profile_id);
  state = state.filter((profileId: number): boolean => profileIds.indexOf(profileId) === -1);

  if (reverse) {
    return [...profileIds.reverse(), ...state];
  }

  return [...state, ...profileIds];
}

function onProfilesFavMeRemoved(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    const index: number = currentState.indexOf(profile.profile_id);
    if (index === -1) {
      return currentState;
    }
    return [...currentState.slice(0, index), ...currentState.slice(index + 1)];
  }, state);
}

export const storeFavMeProfiles = createReducer(
  [] as number[],
  on(prependFavMeProfile, (state, { profiles }) => onProfilesFavMeReceived(state, profiles, true)),
  on(profilesFavMeReceived, (state, { profiles }) => onProfilesFavMeReceived(state, profiles)),
  on(profilesFavMeRemoved, (state, { profiles }) => onProfilesFavMeRemoved(state, profiles)),
  on(profilesFavMeRefreshed, () => []),
);
