import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';

import { ReallySharedModule } from '@libs/really-shared/really-shared.module';
import { AutoresizeDirective } from '@libs/shared/autoresize-textarea/autoresize-textarea.directive';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';

import { BoostToastSubtitlePipe } from '@meupatrocinio/modules/boost/components/boost-toast/pipes/boost-toast-subtitle.pipe';
import { NotificationComponent } from '@meupatrocinio/modules/initial/pages/notification/notification.component';
import { ModalActionButtonComponent } from '@meupatrocinio/modules/main/modals/report-modal/components/modal-action-button/modal-action-button.component';
import { CustomerSupportComponent } from '@meupatrocinio/modules/main/modules/settings/customer-support/customer-support.component';
import { ModalConversationAlreadyDeleted } from '@meupatrocinio/modules/main/pages/conversation-item/modal-conversation-already-deleted/modal-conversation-already-deleted.component';
import { ModalDeleteConversation } from '@meupatrocinio/modules/main/pages/conversation-item/modal-delete-conversation/modal-delete-conversation.component';
import { PaymentMethodOptionComponent } from '@meupatrocinio/modules/main/pages/payment-method-option/payment-method-option.component';
import { BoletoPaymentFormComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/form-boleto-payment/boleto-payment-form.component';
import { CardPaymentFormComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/form-card-payment/card-payment-form.component';
import { PixPaymentFormComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/form-pix-payment/pix-payment-form.component';
import { PaymentHeaderComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/header/payment-header.component';
import { PaymentAdditionalInformationComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/payment-additional-info/payment-additional-information.component';
import { PaymentMethodSelectorComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/payment-method-selector/payment-method-selector.component';
import { PaymentV2Component } from '@meupatrocinio/modules/main/pages/payment-v2/payment-v2.component';
import { BoostToastTitlePipe } from '@meupatrocinio/modules/main/services/toast/pipes/boost/boost-toast-title.pipe';
import { MeltToastTitlePipe } from '@meupatrocinio/modules/main/services/toast/pipes/melt/melt-toast-title.pipe';
import { BadgesExtendedComponent } from '@meupatrocinio/modules/main/shared/badges-extended/badges-extended.component';
import { BadgesComponent } from '@meupatrocinio/modules/main/shared/badges/badges.component';
import { CarouselComponent } from '@meupatrocinio/modules/main/shared/carousel/carousel.component';
import { CouponComponent } from '@meupatrocinio/modules/main/shared/coupon/coupon.component';
import { DateInputDirective } from '@meupatrocinio/modules/main/shared/date-input/date-input.directive';
import { FancyCheckboxComponent } from '@meupatrocinio/modules/main/shared/fancy-checkbox/fancy-checkbox.component';
import { FeedbackNagbarComponent } from '@meupatrocinio/modules/main/shared/feedback-nagbar/feedback-nagbar.component';
import { HelpTooltipComponent } from '@meupatrocinio/modules/main/shared/help-tooltip/help-tooltip.component';
import { ImgSrcsetComponent } from '@meupatrocinio/modules/main/shared/img-srcset/img-srcset.component';
import { InfiniteScrollDirective } from '@meupatrocinio/modules/main/shared/inifinite-scroll/infinite-scroll.directive';
import { LoadingComponent } from '@meupatrocinio/modules/main/shared/loading/loading.component';
import { NagbarDirective } from '@meupatrocinio/modules/main/shared/nagbar/nagbar.directive';
import { PaymentLoadingComponent } from '@meupatrocinio/modules/main/shared/payment-loading/payment-loading.component';
import { PaymentModalFormComponent } from '@meupatrocinio/modules/main/shared/payment-modal-form/payment-modal-form.component';
import { PaymentModalHeaderComponent } from '@meupatrocinio/modules/main/shared/payment-modal-header/payment-modal-header.component';
import { PaymentModalMethodSelectorComponent } from '@meupatrocinio/modules/main/shared/payment-modal-method-selector/payment-modal-method-selector.component';
import { PaymentModalPixFormComponent } from '@meupatrocinio/modules/main/shared/payment-modal-pix-form/payment-modal-pix-form.component';
import { PaymentModalQrCodeResultComponent } from '@meupatrocinio/modules/main/shared/payment-modal-qr-code-result/payment-modal-qr-code-result.component';
import { PaymentNagbarV2Component } from '@meupatrocinio/modules/main/shared/payment-nagbar-v2/payment-nagbar-v2.component';
import { PercentagePipe } from '@meupatrocinio/modules/main/shared/percentage/percentage.pipe';
import { ProfilePreviewSmallComponent } from '@meupatrocinio/modules/main/shared/profile-preview-small/profile-preview-small.component';
import { TestimonialsComponent } from '@meupatrocinio/modules/main/shared/testimonials/testimonials.component';
import { UploadImageBoxComponent } from '@meupatrocinio/modules/main/shared/upload-image-box/upload-image-box.component';
import { UpsellComponent } from '@meupatrocinio/modules/main/shared/upsell/upsell.component';
import { ReferralFriendCounterComponent } from '@meupatrocinio/modules/referral/referral-friend-counter/referral-friend-counter';
import { EmailFieldComponent } from '@meupatrocinio/modules/reg-v5/pages/basic-info/components/email-field/email-field.component';
import { ActionbarBackButtonComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-back-button/actionbar-back-button.component';
import { ActionbarBackTitleComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-back-title/actionbar-back-title.component';
import { ActionbarCallToActionComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-call-to-action/actionbar-call-to-action.component';
import { ActionbarLogoMPComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-logo-mp/actionbar-logo-mp.component';
import { ActionbarPageTitleComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-page-title/actionbar-page-title.component';
import { ActionbarProfileDetailsComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-profile-details/actionbar-profile-details.component';
import { ActionbarThreeDotsComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-three-dots/actionbar-three-dots.component';
import { ActionbarUserImageComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-user-image/actionbar-user-image.component';
import { ActionbarComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar.component';
import { BaseActionbarComponent } from '@meupatrocinio/modules/shared/actionbar/base-actionbar/base-actionbar.component';
import { UserMenuItemsComponent } from '@meupatrocinio/modules/shared/actionbar/user-menu-items/user-menu-items.component';
import { UserMenuComponent } from '@meupatrocinio/modules/shared/actionbar/user-menu/user-menu.component';
import { BirthdateFieldComponent } from '@meupatrocinio/modules/shared/birthdate-field/birthdate-field.component';
import { MembershipBadgeExtendedComponent } from '@meupatrocinio/modules/shared/membership-badge-extended/membership-badge-extended.component';
import { MembershipBadgeComponent } from '@meupatrocinio/modules/shared/membership-badge/membership-badge.component';
import { MessageHeaderComponent } from '@meupatrocinio/modules/shared/message-header/message-header.component';
import { SubscribeButtonComponent } from '@meupatrocinio/modules/shared/subscribe-button/subscribe-button.component';
import { SubscribedStatusComponent } from '@meupatrocinio/modules/shared/subscribed-status/subscribed-status.component';
import { TextFieldComponent } from '@meupatrocinio/modules/shared/text-field/text-field.component';
import { TipComponent } from '@meupatrocinio/modules/shared/tip/tip.component';
import { BadgeService } from '@meupatrocinio/services/badge/badge.service';
import { BADGE_SERVICE_TOKEN } from '@meupatrocinio/services/badge/injection-tokens/injection-token';
import { BlurhashImageComponent } from '@meupatrocinio/utils/components/blurhash-image/blurhash-image.component';
import { ValidationControlErrorComponent } from '@meupatrocinio/utils/components/validation-control-error/validation-control-error.component';
import { ValidationSuccessComponent } from '@meupatrocinio/utils/components/validation-control-success/validation-success.component';
import { ValidationGroupErrorComponent } from '@meupatrocinio/utils/components/validation-group-error/validation-group-error.component';

import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { AlertV2Component } from '@libs/components/alert-v2/alert-v2.component';
import { BrandImageComponent } from '@meupatrocinio/components/brand-image/brand-image.component';
import { PixPaymentFormV2Component } from '@meupatrocinio/modules/main/pages/payment-v2/components/form-pix-payment-v2/form-pix-payment-v2.component';
import { PaymentSelectorV2Component } from '@meupatrocinio/modules/main/pages/payment-v2/components/payment-selector-v2/payment-selector-v2.component';
import { ContactPhoneMaskDirective } from '@meupatrocinio/modules/main/shared/contact-phone-mask/contact-phone-mask.directive';
import { CpfMaskDirective } from '@meupatrocinio/modules/main/shared/cpf-mask/cpf-mask.directive';
import { CreditCardMaskDirective } from '@meupatrocinio/modules/main/shared/credit-card-mask/credit-card-mask.directive';
import { YearMonthMaskDirective } from '@meupatrocinio/modules/main/shared/month-year-mask/month-year-mask.directive';
import { SubmenuV2Component } from '@meupatrocinio/modules/main/shared/submenu-v2/submenu-v2.component';
import { SubmitButtonComponent } from '@meupatrocinio/modules/reg-v5/components/submit-button/submit-button.component';
import { ActionbarBadgesComponent } from '@meupatrocinio/modules/shared/actionbar/actionbar-badges/actionbar-badges.component';
import { CheckboxComponent } from '@meupatrocinio/modules/shared/checkbox/checkbox.component';
import { WhatsAppFormComponent } from '@meupatrocinio/modules/shared/whatsapp/whatsapp.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([]),
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MPWebMissingTranslationHandler,
      },
    }),
    LayoutModule,
    VirtualScrollerModule,
    ReallySharedModule,
    BlurhashImageComponent,
  ],
  providers: [{ provide: BADGE_SERVICE_TOKEN, useClass: BadgeService }],
  exports: [
    ActionbarComponent,
    ActionbarBackButtonComponent,
    ActionbarBackTitleComponent,
    ActionbarCallToActionComponent,
    ActionbarLogoMPComponent,
    ActionbarUserImageComponent,
    ActionbarThreeDotsComponent,
    ActionbarBadgesComponent,
    ActionbarProfileDetailsComponent,
    ActionbarPageTitleComponent,
    BaseActionbarComponent,
    CarouselComponent,
    CouponComponent,
    UserMenuComponent,
    SubmenuV2Component,
    FancyCheckboxComponent,
    UploadImageBoxComponent,
    HelpTooltipComponent,
    LoadingComponent,
    NotificationComponent,
    NagbarDirective,
    PaymentModalHeaderComponent,
    PaymentModalFormComponent,
    PaymentModalPixFormComponent,
    PaymentLoadingComponent,
    PaymentModalMethodSelectorComponent,
    DateInputDirective,
    PaymentV2Component,
    PaymentAdditionalInformationComponent,
    PaymentMethodSelectorComponent,
    PaymentHeaderComponent,
    CardPaymentFormComponent,
    BoletoPaymentFormComponent,
    PixPaymentFormComponent,
    PaymentModalQrCodeResultComponent,
    PaymentMethodOptionComponent,
    VirtualScrollerModule,
    UserMenuItemsComponent,
    MessageHeaderComponent,
    FeedbackNagbarComponent,
    ProfilePreviewSmallComponent,
    ReactiveFormsModule,
    InfiniteScrollDirective,
    CustomerSupportComponent,
    ImgSrcsetComponent,
    MeltToastTitlePipe,
    BoostToastTitlePipe,
    PercentagePipe,
    BoostToastSubtitlePipe,
    BadgesComponent,
    BadgesExtendedComponent,
    MembershipBadgeComponent,
    MembershipBadgeExtendedComponent,
    SubscribedStatusComponent,
    SubscribeButtonComponent,
    TestimonialsComponent,
    ReferralFriendCounterComponent,
    UpsellComponent,
    ModalActionButtonComponent,
    ReallySharedModule,
    TipComponent,
    ModalDeleteConversation,
    ModalConversationAlreadyDeleted,
    TextFieldComponent,
    ValidationSuccessComponent,
    ValidationControlErrorComponent,
    BirthdateFieldComponent,
    ValidationGroupErrorComponent,
    EmailFieldComponent,
    AutoresizeDirective,
    BlurhashImageComponent,
    PaymentSelectorV2Component,
    SubmitButtonComponent,
    PixPaymentFormV2Component,
    AlertV2Component,
    WhatsAppFormComponent,
    CheckboxComponent,
    BrandImageComponent,
  ],
  declarations: [
    ActionbarComponent,
    ActionbarBackButtonComponent,
    ActionbarBackTitleComponent,
    ActionbarCallToActionComponent,
    ActionbarLogoMPComponent,
    ActionbarUserImageComponent,
    ActionbarThreeDotsComponent,
    ActionbarBadgesComponent,
    ActionbarProfileDetailsComponent,
    ActionbarPageTitleComponent,
    BaseActionbarComponent,
    CarouselComponent,
    CouponComponent,
    UserMenuComponent,
    SubmenuV2Component,
    UserMenuItemsComponent,
    FancyCheckboxComponent,
    UploadImageBoxComponent,
    HelpTooltipComponent,
    LoadingComponent,
    NotificationComponent,
    NagbarDirective,
    PaymentModalHeaderComponent,
    PaymentModalFormComponent,
    PaymentModalPixFormComponent,
    PaymentLoadingComponent,
    PaymentModalMethodSelectorComponent,
    DateInputDirective,
    PaymentV2Component,
    PaymentAdditionalInformationComponent,
    PaymentMethodSelectorComponent,
    PaymentHeaderComponent,
    CardPaymentFormComponent,
    BoletoPaymentFormComponent,
    PixPaymentFormComponent,
    PaymentModalQrCodeResultComponent,
    PaymentMethodOptionComponent,
    MessageHeaderComponent,
    FeedbackNagbarComponent,
    ProfilePreviewSmallComponent,
    InfiniteScrollDirective,
    CustomerSupportComponent,
    ImgSrcsetComponent,
    MeltToastTitlePipe,
    BoostToastTitlePipe,
    PercentagePipe,
    BoostToastSubtitlePipe,
    BadgesComponent,
    BadgesExtendedComponent,
    MembershipBadgeComponent,
    MembershipBadgeExtendedComponent,
    SubscribedStatusComponent,
    SubscribeButtonComponent,
    TestimonialsComponent,
    ReferralFriendCounterComponent,
    PaymentNagbarV2Component,
    UpsellComponent,
    ModalActionButtonComponent,
    TipComponent,
    ModalDeleteConversation,
    ModalConversationAlreadyDeleted,
    TextFieldComponent,
    ValidationSuccessComponent,
    ValidationControlErrorComponent,
    BirthdateFieldComponent,
    ValidationGroupErrorComponent,
    EmailFieldComponent,
    AutoresizeDirective,
    PaymentSelectorV2Component,
    SubmitButtonComponent,
    PixPaymentFormV2Component,
    AlertV2Component,
    CpfMaskDirective,
    YearMonthMaskDirective,
    ContactPhoneMaskDirective,
    WhatsAppFormComponent,
    CheckboxComponent,
    CreditCardMaskDirective,
    BrandImageComponent,
  ],
})
export class SharedModule {
  //
}
