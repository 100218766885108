import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BreakpointObserver } from '@angular/cdk/layout';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { Medias } from '@libs/utils/medias';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'mp-actionbar-profile-details',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './actionbar-profile-details.html',
  styleUrls: ['./actionbar-profile-details.scss'],
  standalone: false,
})
export class ActionbarProfileDetailsComponent implements OnInit, OnDestroy {
  @Input() isOnlineStatus = false;
  @Input() canShowBadges = false;
  @Input() profile: IProfile;
  @Input() isWriting = false;
  @Input() isFavoriteIconVisible = false;
  private removeSubscriptions$ = new Subject();

  constructor(
    protected translate: TranslateService,
    protected auth: AuthenticationService,
    protected profileService: ProfileService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorReference: ChangeDetectorRef,
  ) {
    //
  }

  public ngOnInit() {
    this.subscribeToBreakpointer();
  }

  public ngOnDestroy() {
    this.removeSubscriptions$.next();
    this.removeSubscriptions$.complete();
  }

  public isOfficialProfile() {
    return UserCommon.isOfficialProfile(this.profile);
  }

  public subscribeToBreakpointer() {
    this.breakpointObserver
      .observe(Medias.MAX_WIDTH_450)
      .pipe(takeUntil(this.removeSubscriptions$))
      .subscribe({
        next: () => {
          this.markForChangeDetection();
        },
      });
  }

  public getPicture() {
    return ImageHelper.getProfilePicture(this.profile, this.auth.get(), UserCommon.PICTURE_EXTRA_LARGE);
  }

  public getProfileLocation() {
    if (this.profile === undefined || !this.profile.cityName || !this.profile.stateName) {
      return '';
    }

    return this.translate.instant('modules.main.pages.profile.user_location', {
      city: this.profile.cityName,
      province: this.profile.stateName,
    });
  }

  public isMediumScreen() {
    return this.breakpointObserver.isMatched(Medias.MAX_WIDTH_450);
  }

  public profileHasLargeUsername() {
    return this.profile.username.length > 12;
  }

  public getWrapperClass() {
    if (!this.isMediumScreen() || !this.profileHasLargeUsername()) {
      return '';
    }

    return 'actionbar-profile-container__profile-info__personal-info__translate-wrapper';
  }

  getLargeUsernameClass() {
    if (!this.isMediumScreen() || !this.profileHasLargeUsername()) {
      return '';
    }

    return 'actionbar-profile-container__profile-info__personal-info__translate__username--large';
  }

  getLocationExtraClass() {
    if (!this.isMediumScreen() || !this.profileHasLargeUsername()) {
      return '';
    }

    return 'actionbar-profile-container__profile-info__location--extra';
  }

  getLargeTranslateClass() {
    if (!this.isMediumScreen() || !this.profileHasLargeUsername()) {
      return '';
    }

    return 'actionbar-profile-container__profile-info__personal-info__translate--large';
  }

  public isLiked() {
    return this.isFavoriteIconVisible && this.profileService.isProfileFavorite(this.profile);
  }

  private markForChangeDetection(): void {
    this.changeDetectorReference.markForCheck();
  }
}
