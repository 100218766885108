import { IStats } from '@libs/shared/user/stats';
import { statsReceived } from '@libs/store/authentication/actions/stats.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_STATS_STATE: IStats = {};

export const statsReducer = createReducer(
  INITIAL_STATS_STATE,
  on(statsReceived, (_, stats) => ({ ...stats })),
);
