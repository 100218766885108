import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[mp-nagbar-host]',
  standalone: false,
})
export class NagbarDirective {
  constructor(public viewContainerRef: ViewContainerRef) {
    //
  }
}
