import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent, Modal } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

@Component({
  selector: 'mp-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './modal.html',
  standalone: false,
})
export class ModalComponent implements IModalComponent<Modal> {
  constructor(
    protected modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: Modal,
  ) {
    //
  }

  close(): void {
    if (this.data && this.data.closeCallback) {
      this.data.closeCallback();
    }

    this.modalRef.close();
  }

  ok(): void {
    if (this.data && this.data.okCallback) {
      this.data.okCallback();
    }

    this.close();
  }

  cancel(): void {
    if (this.data && this.data.cancelCallback) {
      this.data.cancelCallback();
    }

    this.close();
  }

  isConfirm(): boolean {
    return this.data.type === 'confirm';
  }
}
