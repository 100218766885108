import { IProfile } from '@libs/shared/profile/profile';
import { IBoostLastViewersState } from '@libs/store/boost-last-viewers';
import * as BoostLastViewersActions from '@libs/store/boost-last-viewers/actions';
import { boostLastViewersAdapter, boostLastViewserInitialState } from '@libs/store/boost-last-viewers/state';
import { createReducer, on } from '@ngrx/store';

export const boostLastViewersReducer = createReducer(
  boostLastViewserInitialState,
  on(
    BoostLastViewersActions.setBoostLastViewers,
    (state: IBoostLastViewersState, { profiles }: { profiles: IProfile[] }): IBoostLastViewersState => {
      const profilesWithParsedPhoto: IProfile[] = profiles.map((profile: IProfile): IProfile => {
        return {
          ...profile,
          photo: getPhotoObject(profile),
        };
      });

      return boostLastViewersAdapter.upsertMany(profilesWithParsedPhoto, state);
    },
  ),
  on(BoostLastViewersActions.clearBoostLastViewers, (state: IBoostLastViewersState): IBoostLastViewersState => {
    return boostLastViewersAdapter.removeAll(state);
  }),
);

function getPhotoObject(profile: IProfile) {
  if (typeof profile.photo === 'string') {
    try {
      return JSON.parse(profile.photo);
    } catch {
      return null;
    }
  }

  return profile.photo;
}
