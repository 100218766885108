import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
  name: 'meltToastTitle',
  pure: true,
  standalone: false,
})
export class MeltToastTitlePipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {
    //
  }

  transform(profiles: IProfile[] | undefined): string {
    if (profiles === undefined || profiles.length === 0) {
      return '';
    }

    const lastProfile: IProfile = profiles[profiles.length - 1];

    if (profiles.length === 1) {
      return lastProfile.username ?? '';
    }

    return (
      lastProfile.username +
      this.translateService.instant('modules.main.shared.mp_toast.and_more', {
        numProfiles: profiles.length - 1,
      })
    );
  }
}
