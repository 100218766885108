<div
    class="modal-dialog"
    *ngIf="cropperImage"
>
    <div class="modal-content">
        <div class="modal-header cropped-button-group">
            <button
                id="upload-photo-button-exit"
                class="btn btn-sm btn-link"
                [title]="'common.close' | translate"
                [disabled]="sendingPhoto"
                (click)="handleCloseModal()"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE5CD;
                </i>
            </button>
            <button
                id="upload-photo-button-rotate-left"
                class="btn btn-sm btn-link"
                [title]="'common.image_cropper.rotate_left' | translate"
                [disabled]="sendingPhoto"
                (click)="rotateCrop(-90)"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE419;
                </i>
            </button>
            <button
                id="upload-photo-button-rotate-right"
                class="btn btn-sm btn-link"
                [title]="'common.image_cropper.rotate_right' | translate"
                [disabled]="sendingPhoto"
                (click)="rotateCrop(90)"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE41A;
                </i>
            </button>
            <button
                class="btn btn-sm btn-link upload-photo-button-submit !tw-bg-primary-base"
                [title]="'common.save' | translate"
                [disabled]="sendingPhoto"
                (click)="prepareUploadPhoto()"
            >
                {{ 'common.save' | translate }}
            </button>
        </div>
        <div class="modal-body">
            <lib-mp-cropperjs
                #angularCropper
                [cropperOptions]="cropperOptions"
                [imageUrl]="cropperImage"
            ></lib-mp-cropperjs>
        </div>
    </div>
</div>
