import { IProfile } from '@libs/shared/profile/profile';
import {
  profilesSearchReceived,
  profilesSearchRefreshed,
  profilesSearchRemoved,
} from '@libs/store/profiles/actions/search.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesSearchReceived(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (currentState.includes(profile.profile_id)) {
      return currentState;
    }
    return [...currentState, profile.profile_id];
  }, state);
}

function onProfilesSearchRemoved(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    const index: number = currentState.indexOf(profile.profile_id);
    if (index === -1) {
      return currentState;
    }
    return [...currentState.slice(0, index), ...currentState.slice(index + 1)];
  }, state);
}

export const storeSearchProfiles = createReducer(
  [] as number[],
  on(profilesSearchReceived, (state, { profiles }) => onProfilesSearchReceived(state, profiles)),
  on(profilesSearchRemoved, (state, { profiles }) => onProfilesSearchRemoved(state, profiles)),
  on(profilesSearchRefreshed, () => []),
);
