import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mp-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TooltipComponent {
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() tooltipText = '';
  @Input() tooltipIcon = '?';
}
