import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeCommon } from '@libs/shared/badge/badge.common';
import { IProfile } from '@libs/shared/profile/profile';
import { BadgeService } from '@meupatrocinio/services/badge/badge.service';

@Component({
  selector: 'mp-actionbar-badges',
  templateUrl: './actionbar-badges.component.html',
  styleUrls: ['./actionbar-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ActionbarBadgesComponent {
  @Input() profile!: IProfile;

  constructor(private readonly badgeService: BadgeService) {
    //
  }

  public get badgeIcon() {
    return BadgeCommon.actionbarBadges(this.badgeService)
      .filter((badge) => badge.check(this.profile))
      .map((badge) => ({
        name: badge.name,
        file: this.getBadgeIcon(badge.imageName),
        altText: badge.altText,
      }));
  }

  protected getBadgeIcon(iconName: string): string {
    return BadgeCommon.getIcon(iconName, '.svg');
  }
}
