import { ILoginTokenData } from '@libs/shared/interfaces/login-token-data.interface';
import { tokenReceived } from '@libs/store/authentication/actions/token.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_TOKEN_STATUS: ILoginTokenData = {
  token: '',
};

export const tokenReducer = createReducer(
  INITIAL_TOKEN_STATUS,
  on(tokenReceived, (_, { token }) => ({
    token: typeof token === 'string' && token.length > 0 ? token : INITIAL_TOKEN_STATUS.token,
  })),
);
