import { createReducer, on } from '@ngrx/store';

import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';
import * as BoostPackagesActions from '@libs/store/boost-packages/actions';
import { IBoostPackagesState, boostPackagesAdapter, boostPackagesInitialState } from '@libs/store/boost-packages/state';

export const boostPackagesReducer = createReducer(
  boostPackagesInitialState,
  on(
    BoostPackagesActions.setBoostPackages,
    (
      _: IBoostPackagesState,
      {
        boostPackages,
        productUuid,
        refreshData,
      }: {
        boostPackages: IBoostPackage[];
        productUuid: string;
        refreshData: number;
      },
    ): IBoostPackagesState => {
      return boostPackagesAdapter.setAll(boostPackages, {
        ...boostPackagesInitialState,
        productUuid,
        refreshData,
      });
    },
  ),
  on(
    BoostPackagesActions.setIsPaymentProcessing,
    (state: IBoostPackagesState, { isPaymentProcessing }: { isPaymentProcessing: boolean }): IBoostPackagesState => {
      return {
        ...state,
        isPaymentProcessing,
      };
    },
  ),
);
