import {
  manyProfilesReceived,
  oneProfileReceived,
  removeProfile,
  resetProfileFavorited,
} from '@libs/store/profiles-v2/actions';
import { profilesEntitiesInitialState, profilesEntityAdapter } from '@libs/store/profiles-v2/state';
import { createReducer, on } from '@ngrx/store';

export const profilesReducer = createReducer(
  profilesEntitiesInitialState,
  on(manyProfilesReceived, (state, { profiles }) => {
    return profilesEntityAdapter.upsertMany(profiles, state);
  }),
  on(oneProfileReceived, (state, { profile }) => {
    return profilesEntityAdapter.upsertOne(profile, state);
  }),
  on(resetProfileFavorited, (state, { profileId }) => {
    return profilesEntityAdapter.updateOne({ id: profileId, changes: { favorited: 0 } }, state);
  }),
  on(removeProfile, (state, { profileId }) => {
    return profilesEntityAdapter.removeOne(profileId, state);
  }),
);
