import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesFeaturedPayload {
  profiles: IProfile[];
}

export interface IFeaturedTTLPayload {
  ttl: number;
}

export const profilesFeaturedReceived = createAction('[Profiles Featured] Received', props<IProfilesFeaturedPayload>());

export const profilesFeaturedRemoved = createAction('[Profiles Featured] Removed', props<IProfilesFeaturedPayload>());

export const profilesFeaturedRefreshed = createAction('[Profiles Featured] Refreshed');

export const featuredTTLUpdated = createAction('[Profiles Featured] TTL Updated', props<IFeaturedTTLPayload>());
