import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[monthYearMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearMonthMaskDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class YearMonthMaskDirective {
  private onChange: any;
  private onTouched: any;

  private placeholder = '__/__';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.setAttribute(this.el.nativeElement, 'placeholder', this.placeholder);
  }

  private applyFormat(value: string) {
    const cleaned = value.replace(/\D/g, '').substring(0, 4);
    const length = cleaned.length;

    if (length <= 2) {
      return cleaned;
    }

    return cleaned.replace(/(\d{2})(\d+)/, '$1/$2');
  }

  private removeFormat(value: string) {
    return value.replace(/\D/g, '');
  }

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string): void {
    const unformattedValue = this.removeFormat(value);
    const formattedValue = this.applyFormat(unformattedValue);

    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

    if (this.onChange) {
      this.onChange(formattedValue);
    }
  }

  writeValue(value: any) {
    const formattedValue = value ? this.applyFormat(value) : '';
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('blur')
  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
