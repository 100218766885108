import { Component } from '@angular/core';

import { MembershipBadgeComponentCommon } from '@libs/components/membership-badge/membership-badge.component.common';

@Component({
  selector: 'mp-membership-badge',
  templateUrl: 'membership-badge.html',
  standalone: false,
})
export class MembershipBadgeComponent extends MembershipBadgeComponentCommon {
  //
}
