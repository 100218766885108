import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PaymentMethodOptionComponentCommon } from '@libs/modules/main/pages/payment-method-option/payment-method-option.component.common';
import { IPaymentOptionBrandData } from '@libs/modules/main/pages/payment-method-option/payment-option-brand-data';

@Component({
  selector: 'mp-payment-method-option',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './payment-method-option.html',
  standalone: false,
})
export class PaymentMethodOptionComponent extends PaymentMethodOptionComponentCommon {
  getBrandUrl(brand: IPaymentOptionBrandData): string {
    return `/assets/img/${brand.name}${brand.format}`;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
