import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mp-checkbox',
  templateUrl: './checkbox.component.html',
  standalone: false,
})
export class CheckboxComponent {
  @Input() controlName = '';
  @Input() form = new UntypedFormGroup({});
  @Output() onChecked = new EventEmitter<boolean>();
}
