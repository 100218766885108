import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mp-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SubmitButtonComponent {
  @Input() isSaving = false;
  @Input() shouldDisable = false;
  @Input() text = '';
}
