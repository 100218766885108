import { createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as ViewToastActions from '@libs/store/view-toast/actions';
import { IViewToastState, viewToastAdapter, viewToastInitialState } from '@libs/store/view-toast/state';

export const viewToastReducer = createReducer(
  viewToastInitialState,
  on(ViewToastActions.upsertProfile, (state: IViewToastState, { profile }: { profile: IProfile }): IViewToastState => {
    return viewToastAdapter.upsertOne(profile, state);
  }),
  on(ViewToastActions.removeAllProfiles, (state: IViewToastState): IViewToastState => {
    return viewToastAdapter.removeAll(state);
  }),
);
