import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { SlidableMenuCommon } from '@libs/modules/main/shared/slidable-menu/slidable-menu.common';
import { SlidableMenuComponentCommon } from '@libs/modules/main/shared/slidable-menu/slidable-menu.component.common';
import { KeyboardCommon } from '@libs/shared/keyboard/keyboard.common';

import { SlidableMenuAnimations } from '@meupatrocinio/modules/main/shared/slidable-menu/slidable-menu.animations';
import { SlidableMenuButtonComponent } from '@meupatrocinio/services/slidable-menu/interface/slidable-menu-component.type';
import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';

@Component({
  selector: 'mp-slidable-menu',
  templateUrl: './slidable-menu.html',
  animations: [SlidableMenuAnimations.slideToggle],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class SlidableMenuComponent extends SlidableMenuComponentCommon implements OnInit, OnDestroy {
  @ViewChild('subscribedStatusContainer', {
    read: ViewContainerRef,
    static: true,
  })
  subscribedStatus: ViewContainerRef;
  @ViewChild('userButtonsContainer', { read: ViewContainerRef, static: true })
  userButtons: ViewContainerRef;

  protected slidableMenuButtons: SlidableMenuButtonComponent[] = [];

  public animationState: string;
  public toggleAnimationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

  constructor(protected slidableMenuService: SlidableMenuService) {
    super(slidableMenuService);
  }

  ngOnInit(): void {
    this.slidableMenuButtons = this.slidableMenuService.getValidSlidableMenuButtons();
    this.slidableMenuService.injectSubscribedStatus(this.subscribedStatus);
    this.slidableMenuService.injectUserButtons(this.slidableMenuButtons, this.userButtons);
  }

  ngOnDestroy(): void {
    this.toggleAnimationStateChanged.complete();
  }

  @HostListener('document: keydown', ['$event']) handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    if (event.key === KeyboardCommon.ESCAPE_KEY_NAME) {
      this.slidableMenuService.closeSlidableMenu();
    }
  }

  onToggleFinished(event: AnimationEvent): void {
    this.toggleAnimationStateChanged.emit(event);
  }

  startClosingAnimation(): void {
    this.animationState = SlidableMenuCommon.ANIMATION_STATE_CLOSED;
  }

  public hasButtonsOnSlidableMenu(): boolean {
    return this.slidableMenuButtons.length !== 0;
  }
}
