import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesViewedPayload {
  profiles: IProfile[];
}

export interface IViewedTTLPayload {
  ttl: number;
}

export const profileViewedMeReceived = createAction('[Profiles Viewed Me] Received', props<IProfilesViewedPayload>());

export const prependProfileViewedMe = createAction('[Profiles Viewed Me] Prepend', props<IProfilesViewedPayload>());

export const profileViewedMeRemoved = createAction('[Profiles Viewed Me] Removed', props<IProfilesViewedPayload>());

export const profilesViewedMeRefreshed = createAction('[Profiles Viewed Me] Refreshed');

export const viewedTTLUpdated = createAction('[Profiles Viewed Me] TTL Updated', props<IViewedTTLPayload>());
