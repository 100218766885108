import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesNewPayload {
  profiles: IProfile[];
}

export interface INewTTLPayload {
  ttl: number;
}

export const profilesNewReceived = createAction('[Profiles New] Received', props<IProfilesNewPayload>());

export const profilesNewRemoved = createAction('[Profiles New] Removed', props<IProfilesNewPayload>());

export const profilesNewRefreshed = createAction('[Profiles New] Refreshed');

export const newTTLUpdated = createAction('[Profiles New] TTL Updated', props<INewTTLPayload>());
