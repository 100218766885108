import { UserCommon } from '@libs/shared/user/user.common';
import { messageCountInputted } from '@libs/store/authentication/actions/message.count.action';
import { userReceived } from '@libs/store/authentication/actions/user.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_USER_STATE: UserCommon = new UserCommon(0);

export const userReducer = createReducer(
  INITIAL_USER_STATE,
  on(userReceived, (_, { user }) => {
    const newUser = new UserCommon(user.profile_id);
    return Object.assign(newUser, user);
  }),
  on(messageCountInputted, (state) => {
    const newState = new UserCommon(state.profile_id);
    return Object.assign(newState, state, {
      conversations_qty: (state.conversations_qty || 0) + 1,
    });
  }),
);
