import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Component({
  selector: 'mp-not-found',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './not-found.html',
  standalone: false,
})
export class NotFoundComponent implements OnInit, OnDestroy {
  public navHidden: boolean;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected auth: AuthenticationService,
    protected router: Router,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('uiState').subscribe((): void => {
        this.navHidden = !this.auth.isAuthenticated();

        if (this.navHidden) {
          this.router.navigate(['initial', 'login']);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public get brand() {
    return Config.brand;
  }
}
