import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { MeltModalAnimations } from '@meupatrocinio/modules/main/modals/melt-modal/melt-modal.animation';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

interface IModalData {
  profile: IProfile;
}

@Component({
  selector: 'mp-melt-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './melt-modal.html',
  animations: MeltModalAnimations,
  standalone: false,
})
export class MeltModalComponent implements OnDestroy, IModalComponent {
  public data: never;
  public user: UserCommon | undefined;

  public subscriptions: Subscription[] = [];

  constructor(
    @Inject(MODAL_DATA) public dataMelt: IModalData,
    protected modalRef: ModalRef,
    protected router: Router,
    protected store: Store<IApplicationState>,
  ) {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        this.user = user;
      }),
    );
  }

  getUserPicture(user: UserCommon | undefined): string {
    const defaultImage: string = `/assets/img/${UserCommon.SILHOUETTE_MALE_FILENAME}.webp`;

    if (user === undefined) {
      return defaultImage;
    }

    if (!user.photo || Object.keys(user.photo).length === 0) {
      return UserCommon.getBasicImage(user.sex, user.membership_type_id);
    }

    return ImageHelper.getImage(user.profile_id, user.photo, UserCommon.PICTURE_EXTRA_LARGE);
  }

  getProfilePicture(profile: IProfile | undefined): string {
    const defaultImage: string = `/assets/img/${UserCommon.SILHOUETTE_MALE_FILENAME}.webp`;

    if (profile === undefined) {
      return defaultImage;
    }

    if (!profile.photo || Object.keys(profile.photo).length === 0) {
      return UserCommon.getBasicImage(profile.sex, profile.membership_type_id);
    }

    return ImageHelper.getImage(profile.profile_id, profile.photo, UserCommon.PICTURE_EXTRA_LARGE);
  }

  closeModal(): void {
    this.modalRef.close();
  }

  initConversation(profile: IProfile): void {
    this.closeModal();

    this.router.navigate(['/main', 'conversation', 'message', profile.profile_id]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
