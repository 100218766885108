import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
  pure: true,
  standalone: false,
})
export class PercentagePipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) {
      return `${0}%`;
    }

    return `${value}%`;
  }
}
