import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DailyMessageLimitModalComponentCommon } from '@libs/modules/shared/modals/daily-message-limit/daily-message-limit-modal.component.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { AnalyticsActions } from '@libs/store/analytics/actions';

import { IDailyModalData } from '@meupatrocinio/modules/main/shared/daily-message-limit-modal/interfaces/daily-modal-data.interface';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Component({
  selector: 'mp-daily-message-limit-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './daily-message-limit-modal.html',
  standalone: false,
})
export class DailyMessageLimitModalComponent
  extends DailyMessageLimitModalComponentCommon
  implements OnInit, IModalComponent<IDailyModalData>
{
  constructor(
    protected auth: AuthenticationService,
    protected modalRef: ModalRef,
    protected store: Store,
    @Inject(MODAL_DATA) public data: IDailyModalData,
  ) {
    super(auth);
    this.isConversation = data && data.isConversation;
  }

  public ngOnInit(): void {
    this.handleAnalyticsEvent();
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public handleAnalyticsEvent(): void {
    this.store.dispatch(
      AnalyticsActions.pushEvent({
        eventName: 'message_limit_reached',
      }),
    );
  }
}
