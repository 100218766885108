import { createReducer, on } from '@ngrx/store';

import * as DeactivateReasonActions from '@libs/store/deactivate-reason/actions';
import { IReason } from '@libs/store/deactivate-reason/interface';
import {
  IDeactivateReasonsState,
  deactivateReasonInitialState,
  reasonAdapter,
} from '@libs/store/deactivate-reason/state';

export const deactivateReasonReducer = createReducer(
  deactivateReasonInitialState,
  on(
    DeactivateReasonActions.upsertDeactivateReasons,
    (state, { reasons }: { reasons: IReason[] }): IDeactivateReasonsState => {
      return reasonAdapter.upsertMany(reasons, state);
    },
  ),
  on(
    DeactivateReasonActions.setCurrentDeactivateReason,
    (state, { currentReasonTextSelected }): IDeactivateReasonsState => {
      return { ...state, currentReasonTextSelected };
    },
  ),
);
