<div class="logo-container">
    <a
        *ngIf="hasLoggedIn()"
        [routerLink]="['/main', 'home']"
    >
        <mp-brand-image
            [src]="'actionbar-logo.svg'"
            [description]="'Logo da barra superior'"
            [class]="{'!tw-h-16': brand === 'meuprincipe'}"
        />
    </a>
    <img
        *ngIf="!hasLoggedIn()"
        [src]="getBrandLogo()"
        [ngClass]="{'!tw-h-16': brand === 'meuprincipe'}"
    >
</div>
