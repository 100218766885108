import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[creditCardMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditCardMaskDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class CreditCardMaskDirective {
  private onChange: any;
  private onTouched: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  private applyFormat(value: string) {
    const cleaned = value.replace(/\D/g, '').substring(0, 19);
    const length = cleaned.length;

    if (length > 19) {
      return cleaned.substring(0, 19);
    }

    if (length <= 4) {
      return cleaned;
    }
    if (length <= 8) {
      return cleaned.replace(/(\d{4})(\d+)/, '$1 $2');
    }
    if (length <= 12) {
      return cleaned.replace(/(\d{4})(\d{4})(\d+)/, '$1 $2 $3');
    }

    if (length <= 16) {
      return cleaned.replace(/(\d{4})(\d{4})(\d{4})(\d+)/, '$1 $2 $3 $4');
    }

    return cleaned.replace(/(\d{4})(\d{4})(\d{4})(\d{4})(\d+)/, '$1 $2 $3 $4 $5');
  }

  private removeFormat(value: string) {
    return value.replace(/\D/g, '');
  }

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string): void {
    const unformattedValue = this.removeFormat(value);
    const formattedValue = this.applyFormat(unformattedValue);

    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

    if (this.onChange) {
      this.onChange(unformattedValue);
    }
  }

  writeValue(value: any) {
    const formattedValue = value ? this.applyFormat(value) : '';
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('blur')
  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
