import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { NagbarCommon } from '@meupatrocinio/modules/nagbar/nagbar.common';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Component({
  selector: 'mp-referral-friendship-day-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './referral-friendship-day-nagbar.html',
  standalone: false,
})
export class ReferralFriendshipDayNagbarComponent {
  static componentName: string = NagbarCommon.REFERRAL_FRIENDSHIP_NAGBAR_COMPONENT_NAME;
  public readonly THIRTY_DAYS_OF_PREMIUM: number = 30;

  constructor(
    protected store: Store<IApplicationState>,
    protected auth: AuthenticationService,
  ) {
    //
  }

  public getFriendshipDayBackground(): string {
    return UserCommon.resolveImageName('nagbar-referral-ad');
  }
}
