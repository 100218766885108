import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, interval, of } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { Trial } from '@libs/shared/trial/trial';
import { UserProfileSelectors } from '@libs/store/authentication';
import { UiSelectors } from '@libs/store/new-ui';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';

import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { UserCommon } from '@libs/shared/user/user.common';
import { ProductUtilitarySelectors } from '@libs/store/product-v2/selectors';
import { Config } from '@meupatrocinio/config';
import { NetcoreFacadeService } from '@meupatrocinio/infra/netcore/services/netcore-facade.service';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { PixPaymentFormComponent } from '@meupatrocinio/modules/main/pages/payment-v2/components/form-pix-payment/pix-payment-form.component';
import { ScriptLoaderService } from '@meupatrocinio/modules/shared/script-dependencies/script-loader.service';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';

import { IApplicationState } from '@libs/store/application-state';

@Component({
  selector: 'mp-payment-v2',
  templateUrl: './payment-v2.html',
  styleUrls: ['payment-v2.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PaymentV2Component implements OnInit, OnDestroy {
  private store = inject(Store<IApplicationState>);
  private route = inject(ActivatedRoute);

  public price: Observable<number> = of(0);
  public installments = 0;
  public currentPaymentMethod: string = PaymentMethods.PAYMENT_CREDIT_CARD;
  public product!: IResolvedProductRouteInfo;
  public isPixPaymentEnabledMembership: boolean = Config.isPixPaymentEnabledMembership;
  public hasResolvedPlans$: Observable<boolean> = this.store.pipe(select(PaymentInfoSelectors.selectHasResolvedPlans));
  public hasLoadedPaymentProvider$ = this.store.pipe(select(UiSelectors.selectHasLoadedPaymentProvider));
  public user$ = this.store.pipe(select(UserProfileSelectors.selectUser));
  public paymentStatus$ = this.store.pipe(select(PaymentInfoSelectors.selectPaymentStatus));
  public hasPrize = false;

  public holderCpf = '';
  public readonly BILL_DESCRIPTION = PaymentCommon.PAYMENT_BILL_DESCRIPTION_MP;
  public shouldHideCpf = false;
  private readonly clearSubscriptions$ = new Subject<void>();
  public readonly PAYMENT_ERROR = PaymentStatus.PAYMENT_ERROR;

  @ViewChild('pixPaymentForm') public pixPaymentForm: PixPaymentFormComponent;

  protected subscriptions: Subscription[] = [];

  constructor(
    protected translateService: TranslateService,
    protected trialService: TrialService,
    protected pixStateFacadeService: PixStateFacadeService,
    protected abTestLoaderService: ABTestsLoaderService,
    protected scriptLoaderService: ScriptLoaderService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected authenticationService: AuthenticationService,
    protected netcoreFacadeService: NetcoreFacadeService,
  ) {
    //
  }

  ngOnInit() {
    this.receiveProductFromRoute();

    this.loadScriptPagseguro();
    this.subscribeToShowPixAlternativePayment();
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this.clearSubscriptions$.next();
    this.clearSubscriptions$.complete();
  }

  public subscribeToShowPixAlternativePayment() {
    this.store
      .pipe(select(PaymentInfoSelectors.selectShowPixAlternativePayment), takeUntil(this.clearSubscriptions$))
      .subscribe((showPixAlternativePayment) => {
        if (!showPixAlternativePayment) {
          return;
        }

        this.handleSetupPixPayment();
      });
  }

  handleSetupPixPayment() {
    this.currentPaymentMethod = PaymentMethods.PAYMENT_PIX;
    this.changeDetectorRef.markForCheck();

    this.store.dispatch(PaymentInfoActions.setShowPixAlternativePayment({ showPixAlternativePayment: false }));
    this.handlePixGeneration();
  }

  handlePixGeneration() {
    interval(1000)
      .pipe(
        filter(() => !!this.pixPaymentForm),
        take(1),
        tap({
          next: () => {
            this.pixPaymentForm.formCpf.get('cpf').setValue(this.holderCpf);
            this.shouldHideCpf = true;
            this.pixPaymentForm.onSubmit();
          },
        }),
        takeUntil(this.clearSubscriptions$),
      )
      .subscribe();
  }

  public setHolderCpf(cpf: string) {
    this.holderCpf = cpf;
  }

  public handleCurrentPaymentMethodChange() {
    this.shouldHideCpf = false;
  }

  protected receiveProductFromRoute() {
    this.subscriptions.push(
      this.route.data.subscribe({
        next: (routeData: Data) => {
          this.handleProductReceivedFromRoute(routeData);
        },
      }),
    );
  }

  protected handleProductReceivedFromRoute(routeData: Data) {
    if (!('product' in routeData)) {
      throw new Error('Product data has not been resolved.');
    }

    const product: IResolvedProductRouteInfo = routeData.product;

    this.product = product;
    this.dispatchHandleAccessToCheckout(product.planPath, product.variantUuid);
    this.setPrice();
    this.handleViewPlansCustomEventNetcore();
    this.resolveHasPrize();
  }

  protected handleViewPlansCustomEventNetcore() {
    this.subscriptions.push(
      this.price
        .pipe(
          filter((price) => price !== 0),
          tap({
            next: (price) => {
              this.netcoreFacadeService.planView(price);
            },
          }),
        )
        .subscribe(),
    );
  }

  protected clearSubscriptions() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  protected dispatchHandleAccessToCheckout(planPath: string, variantUuid: string) {
    this.store.dispatch(
      PaymentInfoActions.handleAccessToCheckout({
        planPath,
        variantUuid,
      }),
    );
  }

  private resolveHasPrize() {
    if (this.product.variantUuid !== ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH) {
      return;
    }

    this.hasPrize = true;
  }

  public getProductPlanTitle() {
    const subscriptionType = MembershipCommon.getMachineMembership(this.product.identifier);

    return this.translateService.instant(`modules.main.pages.payment.product_types.${subscriptionType}_member`);
  }

  public isElite() {
    return this.product.identifier === MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE;
  }

  public isTrial() {
    return this.product?.variantUuid ? this.trialService.isTrialProduct(this.product.variantUuid) : false;
  }

  protected setPrice() {
    this.price = this.getPriceByMembershipAndPeriod().pipe(
      map((price: number | undefined) => {
        if (this.isTrial()) {
          return this.getTrialPrice();
        }

        if (price === undefined) {
          return 0;
        }

        return price;
      }),
    );
  }

  protected getPriceByMembershipAndPeriod() {
    return this.store.pipe(
      select(
        ProductUtilitarySelectors.selectProductByMembershipAndPeriod(this.product.identifier, this.product.periodCode),
      ),
      map((paymentOption) => {
        return paymentOption?.price || 0;
      }),
    );
  }

  protected getTrialPrice() {
    if (!Trial.getEnabledTrial(this.product.periodCode)) {
      return 0;
    }

    return Trial.getEnabledTrial(this.product.periodCode).price.toNumber();
  }

  public canShowMobileTestimonials() {
    return ScreenSizeHelpers.isMobile() && this.canSeeTestimonials();
  }

  public canShowDesktopTestimonials() {
    return ScreenSizeHelpers.isMediumScreenOrLarger() && this.canSeeTestimonials();
  }

  protected canSeeTestimonials() {
    return this.isDaddyMommy();
  }

  protected isDaddyMommy() {
    return MembershipCommon.isDaddyMommyType(this.product.identifier);
  }

  public setInstallments(emittedValue: number): void {
    this.installments = emittedValue;
  }

  public isCardPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_CREDIT_CARD;
  }

  public isBoletoPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_BOLETO;
  }

  public isPixPayment() {
    return this.isPixPaymentEnabledMembership && this.currentPaymentMethod === PaymentMethods.PAYMENT_PIX;
  }

  public getProductDescription() {
    const productType = MembershipCommon.getMachineMembership(this.product.identifier) + '_member';

    const plan = this.translateService.instant(`modules.main.pages.payment.product_types.${productType}`);

    if (!this.product.periodCode) {
      return plan;
    }

    return this.translateService.instant('modules.main.pages.payment.plan_description', {
      plan,
      duration: this.getPlanDuration(),
    });
  }

  protected getPlanDuration() {
    if (Trial.isAtTrialLocation(location.href)) {
      return '';
    }

    return this.translateService.instant('modules.main.pages.payment.plan_duration.months', {
      period: this.product.periodCode,
    });
  }

  protected loadScriptPagseguro() {
    const URL = Config.pagseguroUrlScript;

    this.subscriptions.push(this.scriptLoaderService.loadScript('pagseguroCheckout', URL).pipe(take(1)).subscribe());
  }

  protected get user(): UserCommon {
    return this.authenticationService.get();
  }
}
