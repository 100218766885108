import { IRegion } from '@libs/services/location/region/region';
import { regionsReceived } from '@libs/store/location/actions/regions.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_REGIONS_STATE: IRegion[] = [];

export const regionsReducer = createReducer(
  INITIAL_REGIONS_STATE,
  on(regionsReceived, (_, { regions }) => (regions || []).slice()),
);
