import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MaintenanceComponentCommon } from '@libs/modules/main/pages/maintenance/maintenance.component.common';

@Component({
  selector: 'mp-maintenance',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './maintenance.html',
  standalone: false,
})
export class MaintenanceComponent implements MaintenanceComponentCommon {
  //
}
