import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { InitialNavigationActions } from '@meupatrocinio/effects/initial-navigation/actions';

@Component({
  selector: 'mp-email-verify',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './email-verify.html',
  styleUrls: ['./email-verify.component.scss'],
  standalone: false,
})
export class EmailVerifyComponent {
  protected subscriptions: Subscription[] = [];

  constructor(
    protected route: ActivatedRoute,
    protected store: Store,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe((params: ParamMap): void => {
        this.verifyHash(params.get('hash'));
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
  }

  private verifyHash(hash: string): void {
    this.store.dispatch(
      InitialNavigationActions.verifyEmail({
        hash,
      }),
    );
  }
}
