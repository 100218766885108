import { createReducer, on } from '@ngrx/store';

import {
  setDefaultSelectedPlans,
  setIsResolvingVisiblePlans,
  setSelectedPlan,
} from '@libs/store/upgrade-account/actions';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { upgradeAccountInitialState } from '@libs/store/upgrade-account/state';
import { SetDefaultSelectedPlansAction } from '@libs/store/upgrade-account/types/set-default-selected-plans-action.type';

export const upgradeAccountReducer = createReducer(
  upgradeAccountInitialState,
  on(
    setIsResolvingVisiblePlans,
    (
      state: IUpgradeAccountState,
      { isResolvingVisiblePlans }: { isResolvingVisiblePlans: boolean },
    ): IUpgradeAccountState => {
      return {
        ...state,
        isResolvingVisiblePlans,
      };
    },
  ),
  on(
    setSelectedPlan,
    (
      state: IUpgradeAccountState,
      {
        planUuid,
        membershipTypeId,
      }: {
        planUuid: string;
        membershipTypeId: number;
      },
    ): IUpgradeAccountState => {
      return {
        ...state,
        selectedPlans: {
          ...state.selectedPlans,
          [membershipTypeId]: planUuid,
        },
      };
    },
  ),
  on(
    setDefaultSelectedPlans,
    (state: IUpgradeAccountState, { selectedPlans }: SetDefaultSelectedPlansAction): IUpgradeAccountState => {
      return {
        ...state,
        selectedPlans: {
          ...selectedPlans,
        },
      };
    },
  ),
);
