import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: 'textarea[autoresize]',
  standalone: false,
})
export class AutoresizeDirective implements OnInit {
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement) {
    this.adjust(textArea);
  }

  constructor(protected element: ElementRef) {
    //
  }

  ngOnInit() {
    setTimeout(() => this.adjust(), 0);
  }

  public adjust(textArea?: HTMLTextAreaElement) {
    textArea = textArea || this.element.nativeElement;
    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
