import { IBlogPost } from '@libs/shared/interfaces/blog-post.interface';
import { blogPostsReceived } from '@libs/store/blog/actions/blog-posts.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_BLOG_POSTS_STATE: IBlogPost[] = [];

export const blogReducer = createReducer(
  INITIAL_BLOG_POSTS_STATE,
  on(blogPostsReceived, (_, { posts }) => (posts || []).slice()),
);
