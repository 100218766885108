import { createReducer, on } from '@ngrx/store';

import { MessageCommon } from '@libs/shared/message/message.common';
import { IConversation } from '@libs/store/conversations';
import * as MessageActions from '@libs/store/messages/actions';
import { IMessage } from '@libs/store/messages/interface';
import { IMessageState, MessageAdapter, MessageInitialState } from '@libs/store/messages/state';

export const messageReducer = createReducer(
  MessageInitialState,
  on(MessageActions.upsertMessages, (state: IMessageState, { messages }: { messages: IMessage[] }): IMessageState => {
    return MessageAdapter.upsertMany(messages, state);
  }),
  on(
    MessageActions.deleteByConversation,
    (state: IMessageState, { conversation }: { conversation: IConversation }): IMessageState => {
      return MessageAdapter.removeMany((message: IMessage): boolean => {
        return MessageCommon.isReceiverSenderOrRecipient(message.sender_id, message.recipient_id, conversation.peer_id);
      }, state);
    },
  ),
  on(MessageActions.cleanMessages, (state: IMessageState): IMessageState => {
    return MessageAdapter.removeAll({ ...state, sentFreeMessage: false });
  }),
  on(
    MessageActions.sentFreeMessage,
    (state: IMessageState, { sentFreeMessage }: { sentFreeMessage: boolean }): IMessageState => {
      return { ...state, sentFreeMessage };
    },
  ),
);
