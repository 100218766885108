import { IProfile } from '@libs/shared/profile/profile';
import {
  prependProfileViewedMe,
  profileViewedMeReceived,
  profileViewedMeRemoved,
  profilesViewedMeRefreshed,
} from '@libs/store/profiles/actions/viewed.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesViewedReceived(state: number[], profiles: IProfile[], reverse?: boolean): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (profile.profile_id === undefined) {
      return currentState;
    }

    const index = currentState.indexOf(profile.profile_id);

    if (index !== -1 && reverse) {
      return [profile.profile_id, ...currentState.slice(0, index), ...currentState.slice(index + 1)];
    }

    if (index !== -1) {
      return currentState;
    }

    if (reverse) {
      return [profile.profile_id, ...currentState];
    }

    return [...currentState, profile.profile_id];
  }, state);
}

function onProfileViewedRemoved(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (profile.profile_id === undefined) {
      return currentState;
    }

    const index = currentState.indexOf(profile.profile_id);
    if (index === -1) {
      return currentState;
    }

    return [...currentState.slice(0, index), ...currentState.slice(index + 1)];
  }, state);
}

export const storeViewedMeProfiles = createReducer(
  [] as number[],
  on(prependProfileViewedMe, (state, { profiles }) => onProfilesViewedReceived(state, profiles, true)),
  on(profileViewedMeReceived, (state, { profiles }) => onProfilesViewedReceived(state, profiles)),
  on(profileViewedMeRemoved, (state, { profiles }) => onProfileViewedRemoved(state, profiles)),
  on(profilesViewedMeRefreshed, () => []),
);
