import { createReducer, on } from '@ngrx/store';

import * as LoginActions from '@libs/store/login/actions';
import { ILoginState } from '@libs/store/login/interfaces/login-state.interface';
import { loginInitialState } from '@libs/store/login/state';

export const loginReducer = createReducer(
  loginInitialState,
  on(
    LoginActions.forgotPasswordSendingRequest,
    (state: ILoginState, { sendingRequest }: { sendingRequest: boolean }): ILoginState => {
      return {
        ...state,
        sendingRequest,
      };
    },
  ),
  on(LoginActions.forgotPasswordEmailSent, (state: ILoginState): ILoginState => {
    return {
      ...state,
      emailSent: true,
    };
  }),
  on(
    LoginActions.forgotPasswordEmailNotFound,
    (state: ILoginState, { emailNotFound }: { emailNotFound: boolean }): ILoginState => {
      return {
        ...state,
        emailNotFound,
        sendingRequest: false,
      };
    },
  ),
  on(LoginActions.resetState, (): ILoginState => {
    return {
      ...loginInitialState,
    };
  }),
);
