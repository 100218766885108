<div class="actionbar-profile-container" *ngIf="profile">
    <div class="actionbar-profile-container__wrapper">
        <a class="actionbar-profile-container__link">
            <div
                *ngIf="isOnlineStatus"
                class="actionbar-profile-container__link__tip"
            >
                <mp-tip
                    [borderColor]="'--dark'"
                ></mp-tip>
            </div>
            <img
                class="actionbar-profile-container__link__picture tw-rounded-full"
                [src]="getPicture()"
            >
        </a>
        <div class="actionbar-profile-container__profile-info">
            <div class="actionbar-profile-container__profile-info__personal-info">
                <i *ngIf="isLiked()" class="material-icons">
                    &#xE87D;
                </i>
                <div [ngClass]="getWrapperClass()">
                    <div
                      class="actionbar-profile-container__profile-info__personal-info__translate"
                      [ngClass]="getLargeTranslateClass()"
                    >
                      <span
                        class="actionbar-profile-container__profile-info__personal-info__translate__username"
                        [ngClass]="getLargeUsernameClass()"
                      >
                        {{ profile.username}}
                      </span>
                      @if (!isOfficialProfile()) {
                        <span
                          class="actionbar-profile-container__profile-info__personal-info__translate__age"
                        >
                          {{ profile.age }}
                        </span>
                      }
                    </div>
                </div>
            </div>
            <div
                class="actionbar-profile-container__profile-info__location"
                [ngClass]="getLocationExtraClass()"
            >
                {{ getProfileLocation() }}
            </div>
            <div
                *ngIf="isWriting"
                class="actionbar-profile-container__profile-info__writing"
            >
                {{ 'common.typing' | translate }}
            </div>
        </div>
    </div>
</div>
