import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mp-notification',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './notification.html',
  standalone: false,
})
export class NotificationComponent {
  @Input() title: string | undefined;
  @Input() text: string | undefined;
  @Input() email: string | undefined;
  @Input() buttonText: string | undefined;

  @Output() readonly buttonClick: EventEmitter<undefined> = new EventEmitter();

  buttonClicked(): void {
    this.buttonClick.emit();
  }
}
