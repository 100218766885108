import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { NewPasswordComponentCommon } from '@libs/modules/initial/new-password/new-password.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Component({
  selector: 'mp-new-password',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './new-password.html',
  standalone: false,
})
export class NewPasswordComponent extends NewPasswordComponentCommon {
  constructor(
    public translate: TranslateService,
    protected store: Store<IApplicationState>,
    protected userService: UserService,
    protected profileService: ProfileService,
    protected modalService: ModalService,
    protected auth: AuthenticationService,
    protected formBuilder: FormBuilder,
  ) {
    super(translate, userService, store, profileService, auth, formBuilder);
  }

  alert(_title: string, message: string, _closeButtonTitle: string, callback: () => void): void {
    this.modalService.open(message, callback);
  }
}
