import { createReducer, on } from '@ngrx/store';

import * as PendingMessageActions from '@libs/store/pending-messages/actions';
import { IPendingMessage } from '@libs/store/pending-messages/interface';
import {
  IPendingMessageState,
  PendingMessageAdapter,
  PendingMessageInitialState,
} from '@libs/store/pending-messages/state';

export const pendingMessagesReducer = createReducer(
  PendingMessageInitialState,
  on(
    PendingMessageActions.upsertPendingMessages,
    (
      state: IPendingMessageState,
      { pendingMessages }: { pendingMessages: IPendingMessage[] },
    ): IPendingMessageState => {
      return PendingMessageAdapter.upsertMany(pendingMessages, state);
    },
  ),
  on(
    PendingMessageActions.deletePendingMessage,
    (state: IPendingMessageState, { uuid }: { uuid: string }): IPendingMessageState => {
      return PendingMessageAdapter.removeMany(
        (pendingMessage: IPendingMessage): boolean => pendingMessage.uuid === uuid,
        state,
      );
    },
  ),
);
