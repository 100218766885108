import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { Config } from '@meupatrocinio/config';
import { UserMenuService } from '@meupatrocinio/services/user-menu/user-menu.service';
import { Store } from '@ngrx/store';
import type { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mp-user-menu',
  templateUrl: './user-menu.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class UserMenuComponent implements OnDestroy {
  private store = inject(Store);
  private userMenuService = inject(UserMenuService);
  private router = inject(Router);

  protected subscription: Subscription;
  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  @Input() isSelectedForSearchV2 = false;

  ngOnInit() {
    this.userMenuService.checkRoute(this.router.url);
    this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationEnd) {
        this.userMenuService.checkRoute(event.url);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMenuItems(): boolean {
    return !this.userMenuService.responsiveMode();
  }

  public get brand() {
    return Config.brand;
  }
}
