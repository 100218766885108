import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Config } from '@meupatrocinio/config';

@Component({
  selector: 'mp-brand-image',
  templateUrl: './brand-image.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BrandImageComponent {
  @Input() src: string;
  @Input() description: string;
  @Input() class: string;

  public getBrandImage() {
    return `/assets/img/${Config.brand}/${this.src}`;
  }
}
