import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesNearbyPayload {
  profiles: IProfile[];
}

export interface INearbyTTLPayload {
  ttl: number;
}

export const profilesNearbyReceived = createAction('[Profiles Nearby] Received', props<IProfilesNearbyPayload>());

export const profilesNearbyRemoved = createAction('[Profiles Nearby] Removed', props<IProfilesNearbyPayload>());

export const profilesNearbyRefreshed = createAction('[Profiles Nearby] Refreshed');

export const nearbyTTLUpdated = createAction('[Profiles Nearby] TTL Updated', props<INearbyTTLPayload>());
