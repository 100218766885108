import { IProfile } from '@libs/shared/profile/profile';
import {
  profilesFeaturedReceived,
  profilesFeaturedRefreshed,
  profilesFeaturedRemoved,
} from '@libs/store/profiles/actions/featured.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesFeaturedReceived(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (currentState.includes(profile.profile_id)) {
      return currentState;
    }
    return [...currentState, profile.profile_id];
  }, state);
}

function onProfilesFeaturedRemoved(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    const index: number = currentState.indexOf(profile.profile_id);
    if (index === -1) {
      return currentState;
    }
    return [...currentState.slice(0, index), ...currentState.slice(index + 1)];
  }, state);
}

export const storeFeaturedProfiles = createReducer(
  [] as number[],
  on(profilesFeaturedReceived, (state, { profiles }) => onProfilesFeaturedReceived(state, profiles)),
  on(profilesFeaturedRemoved, (state, { profiles }) => onProfilesFeaturedRemoved(state, profiles)),
  on(profilesFeaturedRefreshed, () => []),
);
