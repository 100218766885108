import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mp-alert-v2',
  templateUrl: './alert-v2.component.html',
  standalone: false,
})
export class AlertV2Component implements AfterViewInit {
  @ViewChild('alert') alertRef!: ElementRef;

  @Input() title = '';
  @Input() hasCloseButton = false;
  @Input() status: 'error' | 'success' | 'warning' = 'error';
  @Input() repositionView = false;
  @Output() close = new EventEmitter<void>();

  ngAfterViewInit(): void {
    if (this.repositionView) {
      this.alertRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public handleAlertStyle() {
    if (this.status === 'error') {
      return 'tw-bg-red-50 tw-text-red-950';
    }

    if (this.status === 'warning') {
      return 'tw-bg-yellow-50 tw-text-yellow-950';
    }

    return 'tw-bg-green-50 tw-text-green-950';
  }
}
