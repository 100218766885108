import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalComponentCommon } from '@libs/modules/shared/modals/modal/modal.component.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mp-pending-approval-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './pending-approval-modal.html',
  standalone: false,
})
export class PendingApprovalModalComponent extends ModalComponentCommon implements IModalComponent {
  public data: never;

  constructor(
    protected modalRef: ModalRef,
    protected store: Store,
  ) {
    super();
  }

  closeModal(): void {
    this.modalRef.close();
  }

  public navigateToUpgradeAccount() {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }
}
