import { IProfile } from '@libs/shared/profile/profile';
import {
  profilesNearbyReceived,
  profilesNearbyRefreshed,
  profilesNearbyRemoved,
} from '@libs/store/profiles/actions/nearby.action';
import { createReducer, on } from '@ngrx/store';

function onProfilesNearbyReceived(state: number[], profiles: IProfile[]): number[] {
  return profiles.reduce((currentState: number[], profile: IProfile): number[] => {
    if (currentState.includes(profile.profile_id)) {
      return currentState;
    }
    return [...currentState, profile.profile_id];
  }, state);
}

function onProfilesNearbyRemoved(state: number[], profiles: IProfile[]): number[] {
  const idsToRemove = profiles.map((profile) => profile.profile_id);

  return state.filter((id) => !idsToRemove.includes(id));
}

export const storeNearbyProfiles = createReducer(
  [] as number[],
  on(profilesNearbyReceived, (state, { profiles }) => onProfilesNearbyReceived(state, profiles)),
  on(profilesNearbyRemoved, (state, { profiles }) => onProfilesNearbyRemoved(state, profiles)),
  on(profilesNearbyRefreshed, () => []),
);
