import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesFavMyPayload {
  profiles: IProfile[];
}

export interface IFavMyTTLPayload {
  ttl: number;
}

export const profilesFavMyReceived = createAction('[Profiles Fav My] Received', props<IProfilesFavMyPayload>());

export const profilesFavMyRemoved = createAction('[Profiles Fav My] Removed', props<IProfilesFavMyPayload>());

export const profilesFavMyRefreshed = createAction('[Profiles Fav My] Refreshed');

export const favMyTTLUpdated = createAction('[Profiles Fav My] TTL Updated', props<IFavMyTTLPayload>());

export const profilesFavMyBind = createAction('[Profiles Fav My] Bind');
