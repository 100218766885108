<div
    class="feedback-nagbar"
    *ngIf="isAbleToShowNagbar()"
>
    <h2>
        {{ 'modules.main.shared.feedback_nagbar.title' | translate }}
    </h2>
    <p>
        {{ 'modules.main.shared.feedback_nagbar.description' | translate }}
    </p>
    <a
        [routerLink]="['/main', 'settings', 'customer-support']"
        (click)="setSupportOrigin()"
        class="!tw-bg-primary-base"
    >
        {{ 'modules.main.shared.feedback_nagbar.send' | translate }}
    </a>
    <button (click)="hide()">{{ 'common.not_now' | translate }}</button>
</div>
