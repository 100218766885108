<mp-loading *ngIf="!confirmEmail"></mp-loading>
<div
    class="step step-confirmation"
    id="confirmation"
    *ngIf="confirmEmail"
>
    <div class="form-field feedback">
        <p>
            <mp-advanced-translate key="modules.frictionless.confirmation_description">
                <a
                    target="_blank"
                    href="https://www.meupatrocinio.com/contato"
                    mp-translate-param="contactLink"
                    class="!tw-text-primary-base"
                >
                    {{ 'modules.frictionless.confirmation_description.contact_link' | translate }}
                </a>
            </mp-advanced-translate>
        </p>
    </div>
    <div class="form-field feedback">
        <label for="signEmail">{{ 'common.email' | translate }}</label>
        <input
            id="signEmail"
            #emailElement
            name="email"
            type="email"
            [placeholder]="'modules.frictionless.email.placeholder' | translate"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            [(ngModel)]="email"
            required
            class="hover:!tw-border-b-primary-base"
        >
        <small>{{ emailError | translate }}</small>
    </div>
    <div
        class="buttons-container"
        id="buttons-conf"
    >
        <button
            class="mp-main-button outline-red bt-md mp-confirmation-btn !tw-border-primary-base !tw-text-primary-base hover:!tw-bg-transparent"
            [disabled]="isEmailEmpty()"
            (click)="sendEmailAgain($event)"
        >
            {{ 'modules.main.shared.mobile_nagbar.confirm_email_send' | translate }}
        </button>
        <button
            class="btn-logout"
            (click)="logout()"
        >
            {{ 'modules.main.shared.home_menu.logout' | translate }}
        </button>
    </div>
</div>
