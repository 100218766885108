import { Injectable, inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import {
  CPFValidations,
  CVVValidations,
  CardNumberValidatons,
  DateValidations,
  HolderValidations,
  getNestableControl,
} from '@libs/services/payment/validators';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { luhnCreditCardValidator } from '@meupatrocinio/utils/validators/credit-card.validator';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentModalFormService {
  protected formBuilder = inject(UntypedFormBuilder);
  protected authenticationService = inject(AuthenticationService);

  public readonly BOOST_PACKAGE_CONTROL_NAME: string = 'PAYMENT_MODAL_CONTROL';
  public readonly HOLDER: string = 'holder';
  public readonly CARD_NUMBER: string = 'cardNumber';
  public readonly DATE: string = 'date';
  public readonly CPF: string = 'cpf';
  public readonly CVV: string = 'cvv';
  public readonly IS_ISSUED_ABROAD: string = 'isIssuedAbroad';
  public readonly INVALID_CVV: string = 'invalidCvv';
  public paymentGroupForm = this.formBuilder.group({
    [this.HOLDER]: ['', HolderValidations.validations.validation],
    [this.CARD_NUMBER]: ['', luhnCreditCardValidator],
    [this.DATE]: ['', DateValidations.validations.validation],
    [this.CVV]: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    [this.CPF]: ['', CPFValidations.validations.validation],
    [this.IS_ISSUED_ABROAD]: [false],
  });

  protected subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscribeToOnLogout();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  subscribeToOnLogout(): void {
    this.subscriptions.push(
      this.authenticationService.onLogout$.subscribe({
        next: (): void => {
          this.clearAllData();
        },
      }),
    );
  }

  getFormGroup() {
    return this.paymentGroupForm;
  }

  getHolder(): string {
    const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  getCardNumber(): string {
    const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  getCvv(): string {
    const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  getCPF(): string {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  getDate(): string {
    const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  getIsIssuedAbroad(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.IS_ISSUED_ABROAD);

    if (control === null) {
      return false;
    }

    return control.value;
  }

  getFormValidationErrosMessage(controlName: string): string {
    const fieldsTovalidate = {
      [this.HOLDER]: HolderValidations.validations.messages,
      [this.CARD_NUMBER]: CardNumberValidatons.validations.messages,
      [this.DATE]: DateValidations.validations.messages,
      [this.CPF]: CPFValidations.validations.messages,
      [this.CVV]: CVVValidations.validations.messages,
    };

    if (fieldsTovalidate[controlName] === undefined) {
      return '';
    }

    const errors: ValidationErrors = getNestableControl(controlName, this.paymentGroupForm).errors;

    return fieldsTovalidate[controlName](errors);
  }

  setIsIssuedAbroad(isIssuedAbroad: boolean): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.IS_ISSUED_ABROAD);

    if (control === null) {
      return;
    }

    control.setValue(isIssuedAbroad);
  }

  setCardNumber(cardNumber: string): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

    if (control === null) {
      return;
    }

    control.setValue(cardNumber);
  }

  setDate(date: string): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

    if (control === null) {
      return;
    }

    control.setValue(date);
  }

  setCVV(cvv: string): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

    if (control === null) {
      return;
    }

    control.setValue(cvv);
  }

  setHolder(holder: string): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

    if (control === null) {
      return;
    }

    control.setValue(holder);
  }

  setCPF(cpf: string): void {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return;
    }

    control.setValue(cpf);
    control.updateValueAndValidity();
  }

  handleDefaultCPF(isIssuedAbroad: boolean): void {
    if (!isIssuedAbroad) {
      this.setCPF('');
      return;
    }

    this.setCPF(PaymentCommon.DEFAULT_CPF);
  }

  isFormValid(): boolean {
    return this.paymentGroupForm.valid;
  }

  isCardNumberPristine(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

    if (control === null) {
      return false;
    }

    return control.pristine;
  }

  isValidDate(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

    if (control === null) {
      return false;
    }

    return control.valid || control.pristine;
  }

  isValidCVV(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

    if (control === null) {
      return false;
    }

    return control.valid;
  }

  isPristineCVV(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

    if (control === null) {
      return false;
    }

    return control.pristine;
  }

  isValidHolder(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

    if (control === null) {
      return false;
    }

    return control.valid || control.pristine;
  }

  isCPFValid(): boolean {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return false;
    }

    return control.valid || control.pristine;
  }

  clearAllData(): void {
    this.getFormGroup().reset({
      [this.HOLDER]: '',
      [this.CARD_NUMBER]: '',
      [this.DATE]: '',
      [this.CVV]: '',
      [this.CPF]: '',
      [this.IS_ISSUED_ABROAD]: false,
    });
  }
}
