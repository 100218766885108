import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { CouponComponentCommon } from '@libs/modules/main/shared/coupon/coupon.component.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IApplicationState } from '@libs/store/application-state';

import { CouponService } from '@meupatrocinio/services/coupon.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';

@Component({
  selector: 'mp-coupon',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './coupon.html',
  standalone: false,
})
export class CouponComponent extends CouponComponentCommon {
  public show = false;
  public paymentSucess = false;
  public membershipType: string;
  public period: string;

  constructor(
    protected store: Store<IApplicationState>,
    protected couponService: CouponService,
    protected router: Router,
    protected modalService: ModalService,
    protected profileService: ProfileService,
    public translate: TranslateService,
  ) {
    super(store, couponService);
  }

  public afterRedeem(data: any): void {
    const membership = MembershipCommon.getMachineMembership(data.membership_type_id);
    this.membershipType = this.translate.instant(`shared.user.memberships.${membership}`);
    this.period = this.translate.instant(`common.periods.${data.unit}`, {
      period: data.period,
    });
    this.paymentSucess = true;
    this.profileService.updateSelf();
    this.modalService.open('modules.main.shared.coupon.redeemed_success', () => {
      this.router.navigate(['main', 'home']);
    });
  }

  public afterRedeemError(errorMessage: string): void {
    this.getErrorMessage(this.translate.instant(errorMessage));
  }

  public showValidationMessage(): void {
    this.getErrorMessage(this.translate.instant('shared.coupon.empty_code_error'));
  }

  public redeemClick(): void {
    this.show = true;
  }
}
