import { Component } from '@angular/core';

import { MembershipBadgeComponentCommon } from '@libs/components/membership-badge/membership-badge.component.common';

@Component({
  selector: 'mp-membership-badge-extended',
  templateUrl: 'membership-badge-extended.html',
  standalone: false,
})
export class MembershipBadgeExtendedComponent extends MembershipBadgeComponentCommon {
  //
}
