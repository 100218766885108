import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { ActionbarBackTitleComponentCommon } from '@libs/modules/shared/actionbar/actionbar-back-title/actionbar-back-title.component.common';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'mp-actionbar-back-title',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-back-title.html',
  standalone: false,
})
export class ActionbarBackTitleComponent extends ActionbarBackTitleComponentCommon {
  private store = inject(Store);

  @Input() title: string | undefined;
  @Input() definedBackAction: (() => void) | undefined;

  public isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);
}
