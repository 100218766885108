import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { ProfilePreviewSmallComponentCommon } from '@libs/modules/main/shared/profile-preview-small/profile-preview-small.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfileContextActions } from '@libs/store/profile-context';

import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-profile-preview-small',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './profile-preview-small.html',
  standalone: false,
})
export class ProfilePreviewSmallComponent extends ProfilePreviewSmallComponentCommon implements OnInit, OnDestroy {
  constructor(
    protected router: Router,
    protected store: Store<IApplicationState>,
  ) {
    super(router, store);
  }

  getProfilePicture(): string {
    return ImageHelper.getProfilePicture(this.profile, this.user, UserCommon.PICTURE_EXTRA_LARGE);
  }

  public handleUrl() {
    this.store.dispatch(
      ProfileContextActions.profileContextReceived({
        profileContext: {
          originName: this.router.url.split('?')[0].split('/').pop(),
          previousUrl: this.router.url,
          promotionName: this.profile.promotionName,
          score: this.profile.score,
          recommendationId: this.profile.recommendationId,
        },
      }),
    );
  }
}
