import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, concatMap, delay, map, tap, withLatestFrom } from 'rxjs/operators';

import { UserCommon } from '@libs/shared/user/user.common';
import { SetTmpTokenAction } from '@libs/store/ui/actions/auth-token.action';
import { ClearAllAction } from '@libs/store/ui/actions/clear-all.action';

import { tokenReceived } from '@libs/store/authentication/actions/token.action';
import { InitialNavigationActions } from '@meupatrocinio/effects/initial-navigation/actions';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Injectable()
export class InitialNavigationEffects {
  private actions$ = inject(Actions);
  private authenticationService = inject(AuthenticationService);
  private router = inject(Router);
  private store = inject(Store);
  private userService = inject(UserService);

  private readonly SPECIAL_TREATMENT_STATUSES_MAP = {
    [UserCommon.STATUS_ON_HOLD]: () => this.authenticationService.handleExpressApproval(),
    [UserCommon.STATUS_PENDING]: () => this.authenticationService.handleExpressApproval(),
    [UserCommon.STATUS_PROFILE_EDIT]: () => this.authenticationService.navigateToEditProfile(),
  };

  redirectByStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitialNavigationActions.redirectByStatus),
        delay(500),
        withLatestFrom(this.store.select('token')),
        concatMap(([action, data]) =>
          iif(
            () => {
              return UserCommon.isConnected(this.authenticationService.get()) && data.token.length > 0;
            },
            of('').pipe(
              concatMap(() => {
                this.redirectUserBasedOnStatus();

                return EMPTY;
              }),
            ),
            of(action).pipe(delay(200)),
          ),
        ),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  verifyEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitialNavigationActions.verifyEmail),
        concatMap(({ hash }) =>
          this.userService.checkEmailKey(hash).pipe(
            map((response) => {
              this.store.dispatch(
                tokenReceived({
                  token: response.token,
                }),
              );

              return InitialNavigationActions.redirectByStatus();
            }),
            catchError((response: HttpErrorResponse) => {
              if (response.error === 'expired.token') {
                return of(
                  InitialNavigationActions.navigateTo({
                    path: ['initial', 'hash-expired'],
                  }),
                );
              }

              return of(
                InitialNavigationActions.navigateTo({
                  path: ['initial', 'hash-unavailable'],
                }),
              );
            }),
          ),
        ),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  changePassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitialNavigationActions.changePassword),
        concatMap(({ hash }) =>
          this.userService.checkForgottenPasswordHash(hash).pipe(
            map((response) => {
              this.store.dispatch(new ClearAllAction());
              this.store.dispatch(
                new SetTmpTokenAction({
                  tmpToken: response.token,
                }),
              );

              return InitialNavigationActions.navigateTo({
                path: ['initial', 'new-password'],
              });
            }),
            catchError((response: HttpErrorResponse) => {
              if (response.error === 'expired.token') {
                return of(
                  InitialNavigationActions.navigateTo({
                    path: ['initial', 'hash-expired'],
                  }),
                );
              }

              return of(
                InitialNavigationActions.navigateTo({
                  path: ['initial', 'hash-unavailable'],
                }),
              );
            }),
          ),
        ),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  navigateTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitialNavigationActions.navigateTo),
        tap(({ path }) => {
          this.router.navigate(path);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private isSpecialTreatmentStatus(status: string) {
    return Object.keys(this.SPECIAL_TREATMENT_STATUSES_MAP).includes(status);
  }

  private executeSpecialTreatment(status: string) {
    this.SPECIAL_TREATMENT_STATUSES_MAP[status]();
  }

  private redirectUserBasedOnStatus() {
    const user = this.authenticationService.get();

    if (this.isSpecialTreatmentStatus(user.status)) {
      this.executeSpecialTreatment(user.status);

      return;
    }

    if (!this.authenticationService.isActive()) {
      this.authenticationService.logout();

      return;
    }

    if (this.authenticationService.attemptRedirectToFailedURL()) {
      return;
    }

    this.authenticationService.onNoForcedRouteFound();
  }
}
