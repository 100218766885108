import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import {
  ReactiveModalAnimations,
  STATES_STR,
} from '@meupatrocinio/components/reactivate-modal/reactivate-modal.animation';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { UserService } from '@meupatrocinio/services/user.service';

interface IModalData {
  token: string;
}

enum STATES {
  STATE_QUESTION,
  STATE_PROCESSING,
  STATE_FINISHED,
}

@Component({
  selector: 'mp-reactivate-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './reactivate-modal.html',
  animations: ReactiveModalAnimations,
  standalone: false,
})
export class ReactivateModalComponent implements IModalComponent<IModalData> {
  state: number = STATES.STATE_QUESTION;
  animating = false;

  constructor(
    @Inject(MODAL_DATA) public data: IModalData,
    protected modalRef: ModalRef,
    protected auth: AuthenticationService,
    protected userService: UserService,
  ) {
    //
  }

  closeModal(): void {
    this.modalRef.close();
  }

  getState(): string {
    if (this.state === STATES.STATE_QUESTION) {
      return STATES_STR.QUESTION;
    }

    if (this.state === STATES.STATE_PROCESSING) {
      return STATES_STR.PROCESSING;
    }

    return STATES_STR.FINISHED;
  }

  reactivate(): void {
    this.state = STATES.STATE_PROCESSING;

    this.userService.reactivate(this.data.token).subscribe((): void => {
      this.auth.setToken(this.data.token);

      setTimeout((): void => {
        this.state = STATES.STATE_FINISHED;
      }, 500);
    });
  }

  isQuestioningAndReady(): boolean {
    return this.state === STATES.STATE_QUESTION && !this.animating;
  }

  isProcessingAndReady(): boolean {
    return this.state === STATES.STATE_PROCESSING && !this.animating;
  }

  isFinishedAndReady(): boolean {
    return this.state === STATES.STATE_FINISHED && !this.animating;
  }

  animationStarted(): void {
    this.animating = true;
  }

  animationFinished(): void {
    this.animating = false;
  }
}
