import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { ConversationSelectors } from '@libs/store/conversations';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mp-user-menu-items',
  templateUrl: 'user-menu-items.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class UserMenuItemsComponent implements OnDestroy {
  private store = inject(Store);
  private auth = inject(AuthenticationService);
  private router = inject(Router);
  private location = inject(Location);

  public readonly PAGE_ID_HOME: number = 0;
  public readonly PAGE_ID_CONVERSATION: number = 1;
  public readonly PAGE_ID_SEARCH: number = 2;
  public readonly PAGE_ID_INTERESTS: number = 3;

  protected pageId = -1;
  protected subscriptions: Subscription[] = [];

  @Input() isSelectedForSearchV2 = false;

  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd || event instanceof NavigationStart) {
          this.onRouteChanged(event.url);
        }
      }),
    );
    this.onRouteChanged(this.location.path());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private onRouteChanged(path: string): void {
    this.pageId = this.pageIdByPath(path);
  }

  public getUnreadConversationsCount$(): Observable<number> {
    return this.store.pipe(select(ConversationSelectors.selectUnreadConversations));
  }

  public showUnreadConversations$(): Observable<boolean> {
    return this.store.pipe(select(ConversationSelectors.hasUnreadConversations));
  }

  private pageIdByPath(path: string): number {
    if (path.indexOf('/main/home') !== -1) {
      return this.PAGE_ID_HOME;
    }

    if (path.indexOf('/main/conversation') !== -1) {
      return this.PAGE_ID_CONVERSATION;
    }

    if (path.indexOf('/main/search') !== -1) {
      return this.PAGE_ID_SEARCH;
    }

    if (path.indexOf('/main/favorites') !== -1) {
      return this.PAGE_ID_INTERESTS;
    }

    return -1;
  }

  public isInActivedPage(page: number): boolean {
    return this.pageId === page;
  }

  public isConnected(): boolean {
    return this.auth.isAuthenticated();
  }

  public get brand() {
    return Config.brand;
  }
}
