import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { destroyAnalytics } from '@libs/store/analytics/actions';
import { ModalViewActions } from '@libs/store/modal-view';

import { Config } from '@meupatrocinio/config';
import { DatadogActions } from '@meupatrocinio/infra/datadog/actions';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ListTTLService } from '@meupatrocinio/services/list-ttl.service';

interface INavigationEvent {
  id?: number;
  url?: string;
  start?: number;
  end?: number;
}

@Component({
  selector: 'mp-app',
  changeDetection: ChangeDetectionStrategy.Default,
  template: '<router-outlet></router-outlet>',
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  private auth = inject(AuthenticationService);
  private router = inject(Router);
  private downloadManager = inject(DownloadManagerService);
  private listsTTL = inject(ListTTLService);
  private renderer2 = inject(Renderer2);
  private store = inject(Store);
  protected navigationEvents: INavigationEvent[] = [];
  protected subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event): void => {
        if (Config.showLogs.routing && event instanceof NavigationStart) {
          this.debugNavigationStart(event);
        }

        if (Config.showLogs.routing && event instanceof NavigationEnd) {
          this.debugNavigationEnd(event);
        }

        if (Config.development && event instanceof NavigationError) {
          console.error(event);
        }
      }),
    );

    this.monitorLogout();
    this.checkReduxName();

    if (Config.showLogs.device) {
      const date = new Date();
      const timeString =
        date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();

      console.log('[' + timeString + '] APP --> AppComponent.ngOnInit()');
    }

    this.setModalViewStateAsClosed();
    this.store.dispatch(DatadogActions.initialize());

    this.injectDesignTokensStylesheet();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  protected setModalViewStateAsClosed(): void {
    this.store.dispatch(
      ModalViewActions.setModalOpened({
        isModalOpened: false,
      }),
    );
  }

  protected checkReduxName(): void {
    const reduxName = localStorage.getItem('reduxName');

    if (reduxName === Config.reduxName) {
      return;
    }

    if (!reduxName) {
      localStorage.clear();
      localStorage.setItem('reduxName', Config.reduxName);
      return;
    }

    localStorage.removeItem(reduxName);
    localStorage.setItem('reduxName', Config.reduxName);
  }

  protected monitorLogout(): void {
    this.subscriptions.push(
      this.auth.onLogout$.subscribe((): void => {
        this.downloadManager.clear();
        this.listsTTL.destroy();
        this.store.dispatch(destroyAnalytics());
      }),
    );
  }

  protected debugNavigationStart(event: NavigationStart): void {
    const date = new Date();
    this.navigationEvents[event.id] = {
      id: event.id,
      url: event.url,
      start: Math.floor(date.getTime()),
    };

    const timeString =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();
    console.log(`[${timeString}] ROUTE START --> ${event.url}`);
  }

  protected debugNavigationEnd(event: NavigationEnd): void {
    if (this.navigationEvents[event.id] === undefined) {
      return;
    }

    const date = new Date();
    this.navigationEvents[event.id].end = Math.floor(date.getTime());
    const timeString =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();
    const loadingTime = this.navigationEvents[event.id].end - this.navigationEvents[event.id].start;

    console.log(`[${timeString}] ROUTE END --> ${event.url} took ${loadingTime}ms`);
  }

  protected injectDesignTokensStylesheet() {
    const themeLink = this.renderer2.createElement('link');
    this.renderer2.setAttribute(themeLink, 'rel', 'stylesheet');
    //TO-DO: change dynamically based on the brand.
    this.renderer2.setAttribute(themeLink, 'href', './assets/tokens/meupatrocinio/tokens.css');
    this.renderer2.appendChild(document.head, themeLink);
  }
}
