import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, fromEvent, throwError } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { FrictionlessRegistrationComponentCommon } from '@libs/modules/frictionless-registration/frictionless-registration.component.common';
import { ICallbackData } from '@libs/shared/interfaces/callback-data.interface';
import { IImageRegistrationPayload } from '@libs/shared/profile/image-registration';
import { LoginInfo } from '@libs/shared/profile/login-info';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ImageClearAllAction, ImageReceivedAction } from '@libs/store/registration/actions/frictionless-image.action';
import { RegistrationClearAction } from '@libs/store/registration/actions/registration.action';
import { SetTmpTokenAction } from '@libs/store/ui/actions/auth-token.action';
import { dataURItoBlob } from '@libs/utils/data-uri-to-blob';

import { Config } from '@meupatrocinio/config';
import { ExplanationModalComponent } from '@meupatrocinio/modules/frictionless-registration/explanation-modal/explanation-modal.component';
import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { ImageService } from '@meupatrocinio/services/image.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { UserService } from '@meupatrocinio/services/user.service';
import { BusinessCommon } from '@meupatrocinio/utils/business-common';

import { tokenReceived } from '@libs/store/authentication/actions/token.action';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CookieService } from 'ngx-cookie-service';

interface ICookie {
  gclid?: string;
  vcid?: string;
  utm_campaign?: string;
  utm_source?: string;
}

@Component({
  selector: 'mp-frictionless-registration',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './frictionless-registration.html',
  standalone: false,
})
export class FrictionlessRegistrationComponent
  extends FrictionlessRegistrationComponentCommon
  implements OnInit, OnDestroy
{
  @ViewChild('registrationModal', { static: true })
  registrationModal: ElementRef;
  @ViewChild('imageInput') imageInput: ElementRef;

  public registering: boolean;
  public registrationError: boolean;
  public token: string;

  protected images: string[];

  protected cookies: ICookie = {
    gclid: null,
    vcid: null,
    utm_campaign: null,
    utm_source: null,
  };

  protected readonly MIN_FILE_SIZE: number = 5120;
  protected readonly MAX_FILE_SIZE: number = 10485760;
  protected isScrollLocked = false;

  protected readonly window: any = window;
  protected readonly oWebviewInterface: any = this.window.nsWebViewInterface;
  protected isWebview = false;

  constructor(
    protected userService: UserService,
    protected store: Store<IApplicationState>,
    protected modalService: ModalService,
    protected cookieService: CookieService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modal: AdvancedModalService,
    protected imageService: ImageService,
    protected analyticsService: AnalyticsService,
  ) {
    super(userService, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.window.onLoadedWebview = (): void => {
      this.isWebview = true;
    };

    document.body.classList.add('registration');

    this.subscriptions.push(
      this.route.params.subscribe((params): void => {
        if (!params.referral) {
          return;
        }

        this.registrationPayload.referral_code = params.referral;
        this.router.navigate(['.'], {
          queryParams: { utm_campaign: 'referral' },
          relativeTo: this.route,
          replaceUrl: true,
        });
      }),
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params): void => {
        for (const key of Object.keys(params)) {
          if (!(key in this.cookies)) {
            continue;
          }

          this.cookies[key] = params[key];
        }
      }),
    );

    for (const key of Object.keys(this.cookies)) {
      if (this.cookies[key] !== null || !this.cookieService.check(key)) {
        continue;
      }

      this.cookies[key] = this.cookieService.get(key);
    }

    this.subscriptions.push(
      fromEvent(window, 'hashchange')
        .pipe(startWith(null))
        .subscribe((): void => {
          this.step = Math.min(
            this.lastStep,
            parseInt(location.hash.substring(2), 10) || FrictionlessCommon.STEP_INITIAL,
          );
          this.maybeToggleBodyScroll();
        }),
    );

    this.subscriptions.push(
      this.store.select('storeImageOnRegistration').subscribe((imageData: IImageRegistrationPayload): void => {
        this.pictureData = imageData.profile_image;
        this.images = imageData.image;
      }),
    );

    this.store.dispatch(TimeToRegisterActions.createRequest());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearAllBodyScrollLocks();
    document.body.classList.remove('registration');
  }

  public commitStep(value?: IRegistrationPayload): void {
    if (FrictionlessCommon.isAtAnyModalStep(this.step)) {
      this.registrationModal.nativeElement.scrollTop = 0;
    }

    super.commitStep(value);
    location.hash = 's' + this.step.toString();
    this.maybeToggleBodyScroll();
  }

  public confirmSkipRegistration(): void {
    this.analyticsService.onFinishLater();
    this.submitRegistration();
  }

  public submitRegistration(): void {
    this.registering = true;
    this.registrationError = false;
    const formData = new FormData();

    this.changePayloadByMembership();

    this.registrationPayload.image = this.images;
    this.registrationPayload.profile_image = this.pictureData;

    for (const key of Object.keys(this.registrationPayload)) {
      const value = this.registrationPayload[key];

      if (value === undefined) {
        continue;
      }

      this.addToPayload(formData, key, value);
    }

    for (const key of Object.keys(this.cookies)) {
      if (this.cookies[key] === null) {
        continue;
      }

      formData.append(key, this.cookies[key]);
    }

    this.userService.registerNewUser(formData).subscribe(
      (response: string): void => {
        if (this.isWebview) {
          this.oWebviewInterface.emit('registered', {
            token: response,
            userdata: this.registrationPayload,
          });
          return;
        }

        this.verifyQueue();
        this.store.dispatch(
          new SetTmpTokenAction({
            tmpToken: response,
          }),
        );
      },
      (error: unknown): void => {
        this.registrationError = true;

        this.analyticsService.registrationFailed({ error });
      },
    );
  }

  public processPicture(event: Event): void {
    const input = <HTMLInputElement>event.target;

    if (input.files === undefined || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    this.imageService.cropPhoto({
      file,
      type: 'public',
      callback: this.getImageUploadedCallback,
      isRegistration: true,
    });

    this.imageInput.nativeElement.value = '';
  }

  public getImageUploadedCallback = (event: ICallbackData): void => {
    const reader = new FileReader();
    reader.onload = (): void => {
      this.store.dispatch(
        new ImageReceivedAction({
          data: {
            profile_image: <string>reader.result,
          },
        }),
      );
    };
    reader.readAsDataURL(event.image);
  };

  public validateStep(value: IRegistrationPayload): void {
    if (FrictionlessCommon.isBabyAtConfirmationStep(this.myType, this.step)) {
      return;
    }

    if (FrictionlessCommon.isBabyWithoutPhotoAheadOfPhotoStep(this.myType, this.step, this.pictureData)) {
      this.modalService.open('modules.frictionless.modal.baby_no_photo', (): void => {
        this.resetStep(FrictionlessCommon.STEP_PICTURE);
      });
      return;
    }

    this.commitStep(value);
  }

  public getAcceptedImageTypes(): string {
    return PhotoCommon.TYPE_PHOTOS_ACCEPTED_IN_UPLOAD;
  }

  public backToStart(): void {
    this.registering = false;
    this.step = FrictionlessCommon.STEP_INITIAL;
  }

  public openExplanationModal(): void {
    this.modal.open(ExplanationModalComponent, {
      data: {
        typeExplanation: this.typeExplanation,
        myGender: this.myGender || UserCommon.GENDER_MAN,
      },
    });
  }

  public sendMyType(type: number): void {
    this.typeExplanation = type;
    this.openExplanationModal();
  }

  public getConfirmEmailClass(): string {
    if (this.confirmEmail) {
      return 'header-center';
    }
    return '';
  }

  getModalHiddenClass(): string {
    if (this.step < FrictionlessCommon.STEP_TERMS) {
      return 'hidden';
    }

    return '';
  }

  public canShowAvatar(): boolean {
    return this.step > FrictionlessCommon.STEP_PICTURE && !this.confirmEmail;
  }

  public canShowStepCount(): boolean {
    return this.step > FrictionlessCommon.STEP_TERMS && !this.confirmEmail;
  }

  public login(): void {
    if (!this.isWebview) {
      this.router.navigate(['initial', 'login']);

      return;
    }

    this.oWebviewInterface.emit('login');
  }

  protected verifyQueue(): void {
    this.userService.isEmailUnique(this.registrationPayload.email).subscribe(
      (data: HttpResponse<any>): void => {
        const message: string = this.userService.getEmailMessageByOKResponse(data);

        if (message !== 'common.email.exists') {
          setTimeout((): void => {
            this.verifyQueue();
          }, 2000);
          return;
        }

        const isBaby: boolean = this.isBaby();
        const isMale = this.registrationPayload.sex === UserCommon.GENDER_MAN;

        this.analyticsService.registrationSubmitted({
          isBaby,
          isMale,
        });

        this.store.dispatch(new ImageClearAllAction());

        if (isBaby) {
          this.registering = false;
          this.confirmEmail = true;
          return;
        }

        const loginInfo = new LoginInfo();
        loginInfo.username = this.registrationPayload.email;
        loginInfo.password = this.registrationPayload.password;

        this.userService.login(loginInfo).subscribe(
          (response): void => {
            this.store.dispatch(
              tokenReceived({
                token: response.body.token,
              }),
            );
            this.store.dispatch(new RegistrationClearAction());
          },
          (err): Observable<never> => {
            this.router.navigate(['/'], { replaceUrl: true });

            return throwError(err);
          },
        );
      },
      (error): void => {
        this.registrationError = true;

        this.analyticsService.registrationFailed({ error });
      },
    );
  }

  protected addToPayload(formData: FormData, key: string, value: any): void {
    if (Array.isArray(value)) {
      for (const arrValue of value) {
        this.addToPayload(formData, `${key}[]`, arrValue);
      }
      return;
    }

    if (/image/.test(key)) {
      formData.append(key, dataURItoBlob(value));
      return;
    }

    formData.append(key, value.toString());
  }

  protected maybeToggleBodyScroll(): void {
    const shouldLockScroll = this.step > FrictionlessCommon.STEP_INITIAL;

    if (shouldLockScroll === this.isScrollLocked) {
      return;
    }

    let scrollLockFunc = disableBodyScroll;
    if (!shouldLockScroll && this.isScrollLocked) {
      scrollLockFunc = enableBodyScroll;
    }

    scrollLockFunc(this.registrationModal.nativeElement);
    this.isScrollLocked = shouldLockScroll;
  }

  protected changePayloadByMembership(): void {
    if (UserCommon.isDaddyMommyByWhat(this.myType)) {
      this.registrationPayload.image = undefined;
      return;
    }

    this.registrationPayload['extended[income]'] = undefined;
    this.registrationPayload['extended[net_worth]'] = undefined;
  }

  protected isBaby(): boolean {
    return this.myType === UserCommon.WHAT_SUGGAR_BABY;
  }

  public getCnpj(): string {
    return BusinessCommon.CNPJ;
  }

  public getBackgroundImagePath(): string {
    return `${Config.imagesCdnUrl}registration-bg.jpg`;
  }

  public get brand() {
    return Config.brand;
  }
}
