import { Injectable } from '@angular/core';

import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

import { datadogRum, RumEvent } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  private excludedErrorEvents: string[] = ['Http failure response for https://api.meupatrocinio.com/login-new: 401 OK'];

  public initialize(): void {
    const { datadog, version } = Config;

    datadogRum.init({
      ...datadog,
      version,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: (event) => this.excludeErrorEvents(event, this.excludedErrorEvents),
    });

    datadogRum.startSessionReplayRecording();
  }

  public setUser(user: UserCommon) {
    if (!user || !user.profile_id || !user.username) {
      return;
    }

    datadogRum.setUser({
      id: user.profile_id.toString(),
      name: user.username,
    });
  }

  public forceSessionReplay() {
    datadogRum.startSessionReplayRecording({ force: true });
  }

  public excludeErrorEvents(event: RumEvent, excludedEvents: string[]): boolean {
    if (event.type === 'error') {
      return !excludedEvents.some((msg) => event.error.message.includes(msg));
    }
    return true;
  }
}
