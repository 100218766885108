<div class="step terms">
    <div class="main-term-text">
        <mp-advanced-translate key="modules.frictionless.warning_text">
            <strong mp-translate-param="warning">
                {{ 'modules.frictionless.warning' | translate }}
            </strong>
            <a
                mp-translate-param="termsLink"
                href="https://www.meupatrocinio.com/termos-e-condicoes-de-uso"
                target="_blank"
                class="!tw-text-primary-base"
            >
                {{ 'modules.frictionless.full_tos' | translate }}
            </a>
        </mp-advanced-translate>
    </div>
    <div class="buttons-container">
        <div class="custom-radio-check mini">
            <input
                id="accept"
                name="agree"
                type="checkbox"
                [(ngModel)]="acceptTerms"
                required
            >
            <label
                id="registration-step0-checkbox-terms" 
                for="accept"
                class="after:!tw-bg-primary-base hover:before:!tw-border-primary-base"
            >
                    {{ 'modules.frictionless.accept_tos' | translate }}
            </label>
        </div>
        <button
            id="registration-step0-button-continue-registration"
            class="mp-main-button bt-shadow bt-lg !tw-bg-primary-base !tw-border-primary-base"
            [disabled]="!acceptTerms"
            (click)="commitStep()"
        >
            {{ 'modules.frictionless.continue' | translate }}
        </button>
    </div>
</div>
