import { createReducer, on } from '@ngrx/store';

import * as ConversationWritingActions from '@libs/store/conversations-writing/actions';
import { IConversationWriting } from '@libs/store/conversations-writing/interface';
import {
  ConversationWritingAdapter,
  ConversationWritingInitialState,
  IConversationWritingState,
} from '@libs/store/conversations-writing/state';

export const conversationWriterReducer = createReducer(
  ConversationWritingInitialState,
  on(
    ConversationWritingActions.setConversationWriting,
    (state: IConversationWritingState, writer: IConversationWriting): IConversationWritingState => {
      return ConversationWritingAdapter.upsertOne(writer, state);
    },
  ),
  on(
    ConversationWritingActions.unsetConversationWriting,
    (state: IConversationWritingState, writer: IConversationWriting): IConversationWritingState => {
      return ConversationWritingAdapter.removeOne(writer.conversation_peer_id, state);
    },
  ),
);
