import { createReducer, on } from '@ngrx/store';

import { refreshRecommendedProfileList, storeRecommendedProfileIdList } from '@libs/store/recommended-list/actions';

export const recommendedListInitialState: number[] = [];

export const searchV2Reducer = createReducer(
  recommendedListInitialState,
  on(storeRecommendedProfileIdList, (state, { profiles }) => {
    const profileIds = profiles.map(({ profile_id }) => profile_id);

    return [...state, ...profileIds];
  }),
  on(refreshRecommendedProfileList, () => []),
);
