import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mp-fancy-dropdown',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './fancy-dropdown.html',
  standalone: false,
})
export class FancyDropdownComponent {
  @Input() label: string;
  @Input() options: any[];
  @Input() notSoFancy: boolean;

  @Output() readonly valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _value: string;
  protected _active: boolean;

  get value(): string {
    return this._value;
  }
  @Input() set value(v: string) {
    if (this.options !== undefined && this.options.indexOf(v) === -1) {
      this._value = null;
      return;
    }

    this._value = v;
  }

  get active(): boolean {
    return this._active;
  }
  @Input() set active(a: boolean) {
    this._active = a;
  }

  public setValue(v: string, e: MouseEvent): void {
    this._value = v;
    this.valueChange.emit(v);
    this.setActive(false);
    e.stopPropagation();
  }

  public setActive(a: boolean): void {
    this._active = a;
    this.activeChange.emit(a);
  }
}
