import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UiActions, UiSelectors } from '@libs/store/new-ui';
import { ProfileActions } from '@libs/store/profile/actions';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { contactPhoneValidator } from '@libs/validators/contact-phone.validator';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mp-whatsapp',
  templateUrl: './whatsapp.component.html',
  standalone: false,
})
export class WhatsAppFormComponent implements OnInit {
  private store = inject(Store);
  private formBuilder = inject(FormBuilder);

  protected form = new UntypedFormGroup({});
  public readonly CONTROL_NAME_TEL = 'contact-phone';
  public readonly CONTROL_NAME_CONFIRM_TEL = 'confirm';
  public readonly CONTROL_NAME_LGPD = 'lgpd';
  public readonly DEFAULT_DDI = 55;

  public isLoading$ = this.store.select(UiSelectors.selectIsLoading);

  ngOnInit() {
    this.setupWhatsAppForm();
    this.setupValidatorsAfterInitializeForm();
  }

  public submit() {
    const contactPhone = Number(55 + FormHelpers.getFormControlValue(this.form, this.CONTROL_NAME_TEL));
    this.store.dispatch(UiActions.setIsLoading({ isLoading: true }));
    this.store.dispatch(ProfileActions.saveContactPhone({ contactPhone }));
  }

  public showErrorFeedbackRequiredNine(): boolean {
    return (
      this.isPhoneNumberLengthValid() &&
      (FormHelpers.hasErrorWithSpecificName(this.form, this.CONTROL_NAME_TEL, 'requiredNine') ||
        FormHelpers.hasErrorWithSpecificName(this.form, this.CONTROL_NAME_CONFIRM_TEL, 'requiredNine'))
    );
  }

  public showSuccessFeedbackRequiredNine(): boolean {
    return (
      this.isPhoneNumberLengthValid() &&
      !FormHelpers.hasErrorWithSpecificName(this.form, this.CONTROL_NAME_CONFIRM_TEL, 'requiredNine') &&
      !FormHelpers.hasErrorWithSpecificName(this.form, this.CONTROL_NAME_TEL, 'requiredNine')
    );
  }

  public showErrorFeedbackIfPhoneAreNotTheSame(): boolean {
    return this.isPhoneNumberLengthValid() && !this.arePhoneNumbersEqual();
  }

  public showSuccessFeedbackIfPhoneAreTheSame(): boolean {
    return this.isPhoneNumberLengthValid() && this.arePhoneNumbersEqual();
  }

  public getForm() {
    return this.form;
  }

  private isPhoneNumberLengthValid(): boolean {
    return (
      FormHelpers.getFormControlValue(this.form, this.CONTROL_NAME_TEL).length === 11 &&
      FormHelpers.getFormControlValue(this.form, this.CONTROL_NAME_CONFIRM_TEL).length === 11
    );
  }

  private setupWhatsAppForm() {
    this.form = this.formBuilder.group({
      [this.CONTROL_NAME_TEL]: new UntypedFormControl('', {
        validators: [Validators.required],
      }),
      [this.CONTROL_NAME_CONFIRM_TEL]: new UntypedFormControl('', {
        validators: [Validators.required],
      }),
      [this.CONTROL_NAME_LGPD]: new UntypedFormControl(false, Validators.requiredTrue),
    });
  }

  private setupValidatorsAfterInitializeForm() {
    this.form
      .get(this.CONTROL_NAME_TEL)
      .setValidators([contactPhoneValidator(this.CONTROL_NAME_CONFIRM_TEL, this.form)]);
    this.form
      .get(this.CONTROL_NAME_CONFIRM_TEL)
      .setValidators([contactPhoneValidator(this.CONTROL_NAME_TEL, this.form)]);
  }

  private arePhoneNumbersEqual(): boolean {
    return (
      FormHelpers.getFormControlValue(this.form, this.CONTROL_NAME_TEL) ===
      FormHelpers.getFormControlValue(this.form, this.CONTROL_NAME_CONFIRM_TEL)
    );
  }
}
