<div class="social-media-list">
    <div
    class="social-media-list__icon-block"
        *ngFor="let icon of socialMediaIconsList; trackBy: trackBySocialMediaIcon"
    >
        <a [href]="icon.socialMediaLink" target="_blank">
            <mp-brand-image
                [class]="'social-media-list__image tw-max-w-full'"
                [src]="icon.imagePath"
                [description]="icon.imageAlt | translate"
            />
        </a>
    </div>
</div>

