import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { IImageCropperResult } from '@libs/integration/mp-cropperjs/lib/interfaces/image-cropper-result.interface';
import { IImageCropperSetting } from '@libs/integration/mp-cropperjs/lib/interfaces/image-cropper-settings.interface';
import { ImageLoadedEvent } from '@libs/integration/mp-cropperjs/lib/interfaces/image-loaded-event';
import { CropperjsService } from '@libs/integration/mp-cropperjs/lib/services/cropperjs/cropperjs.service';

import Cropper from 'cropperjs';

@Component({
  selector: 'lib-mp-cropperjs',
  templateUrl: './mp-cropperjs.component.html',
  styleUrls: ['./mp-cropperjs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MpCropperjsComponent implements OnDestroy {
  @ViewChild('image') image: ElementRef;

  @Input() public imageUrl = '';
  @Input() public settings: IImageCropperSetting = {} as IImageCropperSetting;
  @Input() public cropbox: Cropper.CropBoxData = {} as Cropper.CropBoxData;
  @Input() public loadImageErrorText = '';
  @Input() public cropperOptions: Cropper.Options = {};
  @Output() public export: EventEmitter<IImageCropperResult> = new EventEmitter<IImageCropperResult>();

  public cropper: Cropper;
  public isLoading = true;
  public imageLoadFailed = false;
  public aspectRatio = null;

  constructor(protected cropperService: CropperjsService) {
    //
  }
  @HostListener('zoom', ['$event'])
  onZoom(event: any) {
    if (this.aspectRatio === null) {
      this.aspectRatio = event.detail.oldRatio;
    }

    if (event.detail.ratio >= this.aspectRatio + 2) {
      event.preventDefault();
    }

    if (event.detail.ratio <= this.aspectRatio) {
      this.resetCropperPosition();
    }
  }

  public resetCropperPosition() {
    if (this.cropper) {
      const cropperData = this.cropper.getData();
      const canvasData = this.cropper.getCanvasData();
      this.cropper.reset();
      this.cropper.setCanvasData(canvasData);
      this.cropper.setData(cropperData);
    }
  }

  public ngOnDestroy(): void {
    this.cropperService.removeEventListener();
  }

  public imageLoaded(event: ImageLoadedEvent): void {
    this.aspectRatio = null;
    this.setImageLoadFailed(false);
    this.setIsLoading(true);

    const targetImageElement: HTMLImageElement = event.target;

    const { cropperOptions, cropper, imageResolutionSettings } = this.cropperService.getNewCropperResult({
      imageElement: targetImageElement,
      imageResolutionSettings: this.settings,
      cropper: this.cropper,
      cropbox: this.cropbox,
      cropperOptions: this.cropperOptions,
      callbackListener: this.imageLoadedCallback,
    });

    this.cropper = cropper;
    this.cropperOptions = cropperOptions;
    this.settings = imageResolutionSettings;
  }

  public imageLoadedCallback = (): void => {
    this.setIsLoading(false);
  };

  public imageLoadError(_event: Event): void {
    this.setImageLoadFailed(true);
    this.setIsLoading(false);
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public setImageLoadFailed(imageLoadFailed: boolean): void {
    this.imageLoadFailed = imageLoadFailed;
  }
}
