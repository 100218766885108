import { IBadge } from '@libs/modules/main/pages/conversation-item/conversation-item.component.common';
import { IActionbarBadgesItem } from '@libs/shared/badge/interfaces/actionbar-badges-item.interface';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IBaseProfile, IProfile } from '@libs/shared/profile/profile';
import { ProfileCommon } from '@libs/shared/profile/profile.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { IBadgeService } from '@meupatrocinio/services/badge/interfaces/badge-service.interface';

export abstract class BadgeCommon {
  public static readonly VERIFIED: string = 'verified';
  public static readonly ELITE: string = 'elite';
  public static readonly PREMIUM: string = 'premium';
  public static readonly NONE: string = '';

  public static readonly VERIFIED_GREEN_ICON_FILE_NAME: string = 'verified-green';
  public static readonly VERIFIED_ICON_FILE_NAME: string = 'verified';
  public static readonly BABY_PREMIUM_ICON_FILE_NAME: string = 'sbp-diamond-circle';
  public static readonly ELITE_ICON_FILE_NAME: string = 'elite-icon';

  public static getIcon(imageName: string, suffix = '.jpg'): string {
    const prefix = '/assets/img/';

    return prefix + imageName + suffix;
  }

  public static actionbarBadges(badgeService: IBadgeService): IActionbarBadgesItem[] {
    return [
      {
        name: 'verified',
        check: (profile: IProfile) => badgeService.isVerified(profile),
        imageName: 'shield',
        altText: 'modules.main.pages.profile.member_verified',
      },
      {
        name: 'elite',
        check: (profile: IProfile) => badgeService.isElite(profile),
        imageName: 'elite-badge-only-gold',
        altText: 'modules.main.pages.profile.member_elite',
      },
      {
        name: 'premium',
        check: (profile: IProfile) => this.isPremiumAndEligibleToPremiumBadge(profile),
        imageName: 'sbp-diamond-circle',
        altText: 'modules.main.pages.profile.member_sbp',
      },
      {
        name: 'melt',
        check: (profile: IProfile) => badgeService.isMelt(profile),
        imageName: 'melt-new',
        altText: 'common.list.melt',
      },
      {
        name: 'boost',
        check: (profile: IProfile) => badgeService.isBoost(profile),
        imageName: 'viewed-me-boost-badge-only',
        altText: 'modules.main.pages.profile.saw_me_in_last_boost',
      },
      {
        name: 'new',
        check: (profile: IProfile) => badgeService.isNew(profile),
        imageName: 'new',
        altText: 'common.list.new',
      },
      {
        name: 'official',
        check: (profile: IProfile) => UserCommon.isOfficialProfile(profile),
        imageName: 'official-profile',
        altText: 'modules.main.pages.profile.official_profile',
      },
    ];
  }

  public static getRoundedBadgeImage(badge: string): IBadge {
    return {
      [BadgeCommon.ELITE]: {
        imagePath: BadgeCommon.getIcon(BadgeCommon.ELITE_ICON_FILE_NAME, '.svg'),
        imageAlt: 'modules.main.pages.profile.member_elite',
      },
      [BadgeCommon.PREMIUM]: {
        imagePath: BadgeCommon.getIcon(BadgeCommon.BABY_PREMIUM_ICON_FILE_NAME, '.svg'),
        imageAlt: 'modules.main.pages.profile.member_sbp',
      },
      [BadgeCommon.VERIFIED]: {
        imagePath: BadgeCommon.getIcon(BadgeCommon.VERIFIED_GREEN_ICON_FILE_NAME, '.svg'),
        imageAlt: 'modules.main.pages.profile.member_verified',
      },
      ['']: {
        imagePath: '',
        imageAlt: '',
      },
    }[badge];
  }

  public static getMainBadge(profile: IBaseProfile): string {
    if (typeof profile?.membership_type_id === 'undefined') {
      return BadgeCommon.NONE;
    }

    const { membership_type_id } = profile;

    if (MembershipCommon.isEliteType(membership_type_id)) {
      return BadgeCommon.ELITE;
    }

    if (this.isPremiumAndEligibleToPremiumBadge(profile)) {
      return BadgeCommon.PREMIUM;
    }

    if (UserCommon.isPhotoVerified(profile)) {
      return BadgeCommon.VERIFIED;
    }

    return '';
  }

  public static isPremiumAndEligibleToPremiumBadge(profile: IProfile) {
    return (
      MembershipCommon.isPremium(profile.membership_type_id) ||
      (ProfileCommon.isDaddyExpired(profile) &&
        UserCommon.isMale(profile) &&
        MembershipCommon.isDaddyMommyFreeType(profile.membership_type_id))
    );
  }

  public static getPeerProfileBadge(peerProfile: IProfile): IBadge {
    const mainBadge = BadgeCommon.getMainBadge(peerProfile);

    return BadgeCommon.getRoundedBadgeImage(mainBadge);
  }
}
