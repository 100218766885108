import { createReducer, on } from '@ngrx/store';

import { setHasVerifiedPhoto, setIsUploadingImage, setPhotoInReview } from '@libs/store/photo-verify/actions';
import { IPhotoVerifyState } from '@libs/store/photo-verify/interfaces/photo-verify.interface';
import { photoVerifyInitialState } from '@libs/store/photo-verify/state';

export const photoVerifyReducer = createReducer(
  photoVerifyInitialState,
  on(
    setHasVerifiedPhoto,
    (state: IPhotoVerifyState, { hasPhotoVerified }: { hasPhotoVerified: boolean }): IPhotoVerifyState => {
      return {
        ...state,
        hasPhotoVerified,
      };
    },
  ),
  on(
    setIsUploadingImage,
    (state: IPhotoVerifyState, { isUploadingImage }: { isUploadingImage: boolean }): IPhotoVerifyState => {
      return {
        ...state,
        isUploadingImage,
      };
    },
  ),
  on(setPhotoInReview, (state: IPhotoVerifyState, { photoInReview }: { photoInReview: boolean }): IPhotoVerifyState => {
    return {
      ...state,
      photoInReview,
    };
  }),
);
