import { ICity } from '@libs/services/location/city/city';
import { citiesReceived } from '@libs/store/location/actions/cities.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_CITIES_STATE: ICity[] = [];

export const citiesReducer = createReducer(
  INITIAL_CITIES_STATE,
  on(citiesReceived, (_, { cities }) => (cities || []).slice()),
);
