<div class="modal-dialog modal-report">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="center">
                {{ 'common.report' | translate }}
            </h3>
        </div>
        <div class="modal-body">
            <p>
                {{ 'modules.main.pages.report.intro2' | translate: { username: profileReported.username } }}
            </p>
            <form
                [formGroup]="reportModalForm"
                class="modal-report__form form-group col-xs-12"
            >
                <textarea
                    class="form-control"
                    rows="6"
                    [id]="reason"
                    [formControlName]="reason"
                    [placeholder]="'modules.main.pages.report.place_holder' | translate"
                ></textarea>
            </form>
            <p class="tw-text-xl tw-mt-1.5">{{ 'modules.main.pages.report.disclaimer' | translate }}</p>
        </div>
        <div class="modal-footer">
            <mp-modal-action-button
                [text]="'common.close' | translate"
                (click)="close()"
                [modifierClass]="'btn-default'"
            ></mp-modal-action-button>
            <mp-modal-action-button
                [text]="'common.submit' | translate"
                (click)="submit()"
                [modifierClass]="'btn-danger'"
                [disabled]="reportModalForm.invalid"
            ></mp-modal-action-button>
        </div>
    </div>
</div>
