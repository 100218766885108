import { Pipe, PipeTransform } from '@angular/core';

import { divideBy } from '@libs/utils/generic-checkers/generic-checkers';

@Pipe({
  name: 'divideByOneHundred',
  standalone: false,
})
export class DivideByOneHundred implements PipeTransform {
  transform(value: number): number {
    if (this.isInvalid(value)) {
      return 0;
    }

    return divideBy(100, value);
  }

  public isInvalid(input: unknown): boolean {
    return input === undefined || input === null || typeof input !== 'number';
  }
}
