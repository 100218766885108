<div class="new-password outside-body">
    <div class="outside-banner new-pass visible-md visible-lg"></div>
    <div class="outside-page">
        <div class="container header">
            <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <div class="fluid-container">
                        <div class="row">
                            <div class="col-xs-12">
                                <img src="/assets/img/meu-patrocinio-logo-v02.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div
                class="row new-password-xs-sm-center"
                *ngIf="!(loading$ | async)"
            >
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <h1>
                        {{'modules.main.pages.new_password.title' | translate}}.
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-5 tw-mt-4">
                    <mp-loading *ngIf="loading$ | async"></mp-loading>
                    <div
                        class="page-form new-password-form"
                        *ngIf="!(loading$ | async)"
                    >
                        <form class="row" [formGroup]="form">
                            <div class="form-group col-xs-12">
                                <label for="newpass">
                                    {{'modules.main.pages.change_password.new_password' | translate}}
                                </label>
                                <input
                                    class="form-control"
                                    type="password"
                                    id="newpass"
                                    formControlName="password"
                                    placeholder="{{ 'modules.main.pages.change_password.new_password.placeholder' | translate }}"
                                >
                                <div class="tw-flex tw-flex-col tw-gap-2">
                                    @if (form.get('password').touched && !form.get('password').valid) {
                                        @if (form.get('password').hasError('minlength')) {
                                            <small class="tw-text-red-600">{{ 'modules.main.pages.change_password.password_minlength' | translate }}</small>
                                        }
                                    }
                                </div>
                            </div>
                            <div class="form-group col-xs-12">
                                <label for="newpassconfirm">
                                    {{'modules.main.pages.change_password.confirm_new_password' | translate}}
                                </label>
                                <input
                                    class="form-control"
                                    type="password"
                                    formControlName="passwordConfirmation"
                                    id="newpassconfirm"
                                    placeholder="{{ 'modules.main.pages.change_password.confirm_new_password.placeholder' | translate }}"
                                >
                                @if (form.get('passwordConfirmation').touched && !form.get('passwordConfirmation').valid) {
                                  <div class="tw-flex tw-flex-col tw-gap-2">
                                      @if (form.get('passwordConfirmation').hasError('equalsTo')) {
                                          <small class="tw-text-red-600">{{ 'modules.main.pages.new_password.equals'| translate }}</small>
                                      }
                                  </div>
                                }
                            </div>
                            <div class="form-group col-xs-12">
                                <button
                                    class="btn-lg mp-main-button bt-shadow full-width"
                                    type="submit"
                                    [disabled]="form.invalid"
                                    (click)="proceed()"
                                >
                                    {{ 'modules.main.pages.change_password.save_new_password' | translate }}
                                </button>
                            </div>
                        </form>
                        <p class="small">
                            {{'modules.main.pages.new_password.advise' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
