import { createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as MessageToastActions from '@libs/store/message-toast/actions';
import { IMessageToastState, messageToastAdapter, messageToastInitialState } from '@libs/store/message-toast/state';

export const messageToastReducer = createReducer(
  messageToastInitialState,
  on(
    MessageToastActions.upsertProfile,
    (state: IMessageToastState, { profile }: { profile: IProfile }): IMessageToastState => {
      return messageToastAdapter.upsertOne(profile, state);
    },
  ),
  on(MessageToastActions.removeAllProfiles, (state: IMessageToastState): IMessageToastState => {
    return messageToastAdapter.removeAll(state);
  }),
);
