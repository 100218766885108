import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { ITrialModalData } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { IApplicationState } from '@libs/store/application-state';
import FlipDown from 'flipdown-mp/dist/flipdown';

interface IModalTrial {
  trial: Trial;
  modalData: ITrialModalData;
}

@Component({
  selector: 'mp-trial-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './trial-modal.html',
  standalone: false,
})
export class TrialModalComponent implements IModalComponent, AfterViewInit {
  public readonly TYPE_PREMIUM: MembershipType = MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM;

  public data: never;
  protected flipDown: HTMLElement;
  protected isFlipCounterWorking: boolean;
  protected readonly TRIAL_THREE_DAYS_LEFT: number = 3;
  protected readonly TRIAL_NO_DAYS_LEFT: number = 0;

  constructor(
    protected modalRef: ModalRef,
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
    @Inject(MODAL_DATA) public dataTrial: IModalTrial,
  ) {
    //
  }

  ngAfterViewInit(): void {
    this.flipDown = document.getElementById('flipdown');
    this.startCountdown();
  }

  trial(): Trial {
    return this.dataTrial.trial;
  }

  modalData(): ITrialModalData {
    return this.dataTrial.modalData || {};
  }

  public startCountdown(): void {
    if (!this.canShowCountdown()) {
      return;
    }

    new FlipDown(this.trial().getEndDateTimestampInSeconds(), {
      theme: 'mp',
    })
      .start()
      .ifEnded((): void => {
        this.close();
      });
    this.isFlipCounterWorking = true;
  }

  public close(): void {
    this.isFlipCounterWorking = false;
    this.modalRef.close();
  }

  public get activeTrialRoutePath(): number {
    if (this.dataTrial.trial.getTrialPlanDaysActive() !== Trial.TRIAL_15_DAYS) {
      return Trial.TRIAL_TIME;
    }

    return Trial.TRIAL_15_DAYS;
  }

  public canShowCountdown(): boolean {
    return (
      this.trial().getEndTrialDaysCount() <= this.TRIAL_THREE_DAYS_LEFT &&
      this.trial().getEndTrialDaysCount() >= this.TRIAL_NO_DAYS_LEFT
    );
  }

  public getTrialPlanDescription(): string {
    return this.translate.instant('modules.main.shared.trial_modal.price', {
      trialDays: this.trial().getTrialPlanDaysActive(),
      trialPrice: this.trial().getTrialPrice().toString(),
    });
  }

  public getTrialPlansFinePrint(): string {
    if (this.trial().isEmpty()) {
      return '';
    }

    if (!this.trial().isFifteenDaysTrialPlan()) {
      return this.translate.instant('modules.main.shared.trial_modal.trial_plans.fine_print.months', {
        trialMonths: this.trial().getTrialNextRecurringPeriod(),
        trialPrice: this.trial().getTrialNextRecurringPrice().toString(),
      });
    }

    return this.translate.instant('modules.main.shared.trial_modal.trial_plans.fine_print.fifteenDays', {
      trialPrice: this.trial().getTrialNextRecurringPrice().toString(),
    });
  }

  public getModalTitle(): string {
    if (this.modalData().title === undefined) {
      return 'modules.main.shared.trial_modal.title';
    }

    return this.modalData().title;
  }

  public getDeclineText(): string {
    if (this.modalData().declineText === undefined) {
      return 'common.not_now';
    }

    return this.modalData().declineText;
  }

  public getAcceptButtonText(): string {
    if (this.modalData().buttonText === undefined) {
      return 'modules.main.shared.trial_modal.accept';
    }

    return this.modalData().buttonText;
  }

  public isOriginalTrialPlan(): boolean {
    return this.trial().isOriginalTrialPlan();
  }

  public declineModal(): void {
    this.close();

    if (this.modalData().declineCallback !== undefined) {
      this.modalData().declineCallback();
    }
  }

  public acceptOffer(): void {
    this.close();

    if (this.modalData().successCallback !== undefined) {
      this.modalData().successCallback();
    }
  }
}
