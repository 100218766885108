import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EmailUnsubscribeLoadingComponentCommon } from '@libs/modules/initial/email-unsubscribe-loading/email-unsubscribe-loading.component.common';

import { EmailUnsubscribeAnimations } from '@meupatrocinio/modules/initial/pages/email-unsubscribe/email-unsubscribe.animations';

@Component({
  selector: 'mp-email-unsubscribe-loading',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: 'email-unsubscribe-loading.html',
  animations: [EmailUnsubscribeAnimations.slideToggle],
  standalone: false,
})
export class EmailUnsubscribeLoadingComponent extends EmailUnsubscribeLoadingComponentCommon {
  //
}
