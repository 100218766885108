import { Injectable } from '@angular/core';
import { IProductVariant } from '@libs/modules/product-v2/interfaces/product-catalog.interface';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogService {
  constructor(private readonly authHttp: AuthHttpService) {
    //
  }

  public getMembershipProducts() {
    const endpoint = `${Config.serverIp}v2/catalog/membership-packages`;

    return this.authHttp.get(endpoint);
  }

  public getExpressApprovalProduct() {
    const endpoint = `${Config.serverIp}v2/catalog/express-approval-packages`;

    return this.authHttp.get(endpoint);
  }

  public getBoostProducts() {
    const endpoint = `${Config.serverIp}v2/catalog/boost-packages`;

    return this.authHttp.get(endpoint);
  }

  public getPlanByUuid(uuid: string) {
    const endpoint = `${Config.serverIp}v2/catalog/membership-variant/${uuid}`;

    return this.authHttp.get(endpoint).pipe(
      map((response: IAuthResponse<IProductVariant>): IAuthResponse<IPaymentOption> => {
        const newData = {
          membership_type_id: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
          price: Number.parseFloat((response.data.installments['1'].amount / 100).toFixed(2)),
          period: response.data.period,
          units: response.data.unit,
          uuid: response.data.uuid,
          totalByMonth: response.data.installments['1'].amountPerMonth,
          discount: response.data.percentageDiscount,
        };

        return {
          data: newData,
          status: response.status,
          next_page_url: response.next_page_url,
          last_page: response.last_page,
          total: response.total,
        };
      }),
    );
  }
}
