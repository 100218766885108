import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { ValidationSuccessComponentCommon } from '@libs/utils/components/validation-success/validation-success.component.common';

@Component({
  selector: 'mp-validation-success',
  templateUrl: './validation-success.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValidationSuccessComponent extends ValidationSuccessComponentCommon {
  constructor(protected changeDetectorReference: ChangeDetectorRef) {
    super(changeDetectorReference);
  }
}
