import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

export interface IProfilesFavMePayload {
  profiles: IProfile[];
}

export interface IFavMeTTLPayload {
  ttl: number;
}

export const prependFavMeProfile = createAction('[Profiles Fav Me] Prepend Profile', props<IProfilesFavMePayload>());

export const profilesFavMeReceived = createAction('[Profiles Fav Me] Received', props<IProfilesFavMePayload>());

export const profilesFavMeRemoved = createAction('[Profiles Fav Me] Removed', props<IProfilesFavMePayload>());

export const profilesFavMeRefreshed = createAction('[Profiles Fav Me] Refreshed');

export const favMeTTLUpdated = createAction('[Profiles Fav Me] TTL Updated', props<IFavMeTTLPayload>());
