import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { ICallbackData } from '@libs/shared/interfaces/callback-data.interface';
import { IPhoto } from '@libs/shared/profile/photo';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { Tick } from '@libs/utils/timeout-typings';

import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageService } from '@meupatrocinio/services/image.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';

@Component({
  selector: 'mp-photo-verify-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './photo-verify-modal.html',
  standalone: false,
})
export class PhotoVerifyModalComponent implements OnDestroy, IModalComponent {
  public data: never;
  public photoType = 'friends_only';
  public uploading = false;
  protected subscriptions: Subscription[] = [];
  protected waitUploadTimeout: Tick;
  protected verifiedPhoto: IPhoto;

  constructor(
    protected profileService: ProfileService,
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected auth: AuthenticationService,
    protected modalRef: ModalRef,
    protected modal: AdvancedModalService,
    protected imageService: ImageService,
  ) {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        if (!user) {
          return;
        }

        this.verifiedPhoto = UserCommon.photoToVerify(user);
        if (this.verifiedPhoto) {
          this.uploading = false;
          clearTimeout(this.waitUploadTimeout);
          this.router.navigate(['main', 'photo-verify']);
          this.closeModal();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  cropPhoto(event: Event): void {
    const input = <HTMLInputElement>event.target;

    if (input.files === undefined || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    this.imageService.cropPhoto({
      file,
      type: this.photoType,
      callback: this.getImageUploadedCallback,
      extraParams: {
        verify_photo: 1,
      },
    });
    input.form.reset();
  }

  getImageUploadedCallback = (data: ICallbackData): void => {
    this.uploading = true;

    this.waitUpload();
    data.request.subscribe();
  };

  waitUpload(): void {
    if (this.verifiedPhoto) {
      this.uploading = false;
      return;
    }

    this.profileService.updateSelf();
    this.waitUploadTimeout = setTimeout((): void => {
      this.waitUpload();
    }, 5000);
  }

  public getModalImage(): string {
    return UserCommon.resolveImageName('verify-bg-xs');
  }

  public hasPendingOrVerifiedPhotos(): boolean {
    return UserCommon.hasPendingOrVerifiedPhotos(this.auth.get());
  }

  public isUploading(): boolean {
    return this.uploading;
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  getAcceptedImageTypes(): string {
    return PhotoCommon.TYPE_PHOTOS_ACCEPTED_IN_UPLOAD;
  }
}
