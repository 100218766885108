import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BLURHASH_DEFAULT_QR_CODE,
  RANDOM_PROFILE_PLACEHOLDERS,
} from '@meupatrocinio/utils/components/blurhash-image/constants';

import { lazyLoad } from 'unlazy';

@Component({
  selector: 'app-blurhash-image',
  imports: [CommonModule],
  templateUrl: './blurhash-image.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurhashImageComponent implements AfterViewInit, OnChanges {
  @Input() width: number | string = '100%';
  @Input() height: number | string = '100%';
  @Input() imageUrl!: string;
  @Input() hash?: string;
  @Input() isQrCode = false;
  @Input() lazy = false;

  @ViewChild('imageElement') imageElementRef!: ElementRef<HTMLImageElement>;

  public hashToDecode!: string;
  private observer!: IntersectionObserver;

  ngAfterViewInit() {
    this.hashToDecode = this.hash || this.getRandomBlurhashPlaceholder();
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLImageElement;
          lazyLoad(target, {
            hash: this.hashToDecode,
            hashType: 'blurhash',
          });
          observer.unobserve(entry.target);
        }
      });
    });
    this.observer.observe(this.imageElementRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageUrl'] && !changes['imageUrl'].isFirstChange()) {
      this.loadImage();
    }
  }

  private getRandomBlurhashPlaceholder(): string {
    if (this.isQrCode) {
      return BLURHASH_DEFAULT_QR_CODE;
    }

    const randomIndex = Math.floor(Math.random() * RANDOM_PROFILE_PLACEHOLDERS.length);
    return RANDOM_PROFILE_PLACEHOLDERS[randomIndex];
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private loadImage() {
    if (this.observer) {
      this.observer.unobserve(this.imageElementRef.nativeElement);
    }
    this.observer.observe(this.imageElementRef.nativeElement);
  }
}
