import { ICountry } from '@libs/services/location/country/country';
import { countriesReceived } from '@libs/store/location/actions/countries.action';
import { createReducer, on } from '@ngrx/store';

export const INITIAL_COUNTRIES_STATE: ICountry[] = [];

export const countriesReducer = createReducer(
  INITIAL_COUNTRIES_STATE,
  on(countriesReceived, (_, { countries }) => (countries || []).slice()),
);
