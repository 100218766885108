import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PaymentModalHeaderComponentCommon } from '@libs/modules/main/shared/payment-modal-header/payment-modal-header.component.common';

@Component({
  selector: 'mp-payment-modal-header',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './payment-modal-header.html',
  standalone: false,
})
export class PaymentModalHeaderComponent extends PaymentModalHeaderComponentCommon {}
