import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mp-fancy-checkbox',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './fancy-checkbox.html',
  standalone: false,
})
export class FancyCheckboxComponent {
  public id = '';

  @Input() disabled = false;
  @Output() readonly valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected _value = false;

  get value(): boolean {
    return this._value;
  }
  @Input() set value(value: boolean) {
    this._value = value;
  }

  constructor() {
    this.id = 'fcb_' + Date.now();
  }

  public setValue(value: boolean, event: Event): void {
    this._value = value;
    this.valueChange.emit(value);
    event.stopPropagation();
  }
}
