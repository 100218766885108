import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ISocialMediaList } from '@meupatrocinio/modules/main/shared/social-media-list/interfaces/social-media-list.interface';

@Component({
  selector: 'mp-social-media-list',
  templateUrl: 'social-media-list.html',
  styleUrls: ['social-media-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SocialMediaListComponent {
  public socialMediaIconsList: readonly ISocialMediaList[] = [
    {
      imagePath: `blog_icon.svg`,
      imageAlt: 'common.social_media.blog',
      socialMediaLink: 'https://www.meupatrocinio.com/blog/',
    },
    {
      imagePath: `youtube_icon.svg`,
      imageAlt: 'common.social_media.youtube',
      socialMediaLink: 'https://www.youtube.com/@MeuPatrociniooficial',
    },
    {
      imagePath: `instagram_icon.svg`,
      imageAlt: 'common.social_media.instagram',
      socialMediaLink: 'https://www.instagram.com/oficialmeupatrocinio/',
    },
    {
      imagePath: `tiktok_icon.svg`,
      imageAlt: 'common.social_media.tiktok',
      socialMediaLink: 'https://www.tiktok.com/@meupatrocinio',
    },
  ] as const;

  public trackBySocialMediaIcon(_index: number, item: ISocialMediaList): string {
    return item.imageAlt;
  }
}
